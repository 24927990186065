import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated, selectToken } from '../../../redux/selectors/site/loginSelectors'
import {
  selectListBalances,
  selectListBalancesError,
  selectListBalancesLoading
} from "src/redux/selectors/player/listBalancesSelectors"
import { connectListBalancesWebSocket } from "src/redux/actions/player/listBalances"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { switchAccount, switchAccountSelector } from "../../../redux/slices/switchAccountSlice"
import { LinearProgress } from "@mui/material"
import styles from './Accounts.module.scss'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { SquareButton } from './SquareButton'
import { Balance } from "../../../common/types"
import ModalPlayerDeposit from "../../layouts/modals/ModalPlayerDeposit"
import ModalAddWallet from "../../layouts/modals/ModalAddWallet"

type CheckedStates = {
  [key: number]: boolean
}

const Accounts: React.FC = () => {

  const [isLoadingSocket, setIsLoadingSocket] = useState(false)

  const dispatch = useAppDispatch()

  const listBalances = useSelector(selectListBalances) as Balance[] | null
  const listBalancesLoading = useSelector(selectListBalancesLoading)
  const listBalancesError = useSelector(selectListBalancesError)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const storedToken = useSelector(selectToken)
  const [showModalPlayerDeposit, setShowModalPlayerDeposit] = useState<boolean>(false)
  const [showModalAddWallet, setModalAddWallet] = useState<boolean>(false)

  const [selectedCurrencyId, setSelectedCurrencyId] = useState<number | null>(null)

  const { isLoading: isLoadingAccountSwitch } = useAppSelector(switchAccountSelector)

  useEffect(() => {
    if (isAuthenticated && storedToken) {
      const closeWebSocket = dispatch(connectListBalancesWebSocket(storedToken))

      return () => {
        closeWebSocket()
      }
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    console.log('player list Balances', listBalances)

    const initialCheckedStates = listBalances?.reduce((acc: CheckedStates, balance) => {
      acc[balance.id] = balance.flag_main === 'YES'
      return acc
    }, {})

    if (initialCheckedStates) {
      setCheckedStates(initialCheckedStates)
    }

    setIsLoadingSocket(false)
  }, [listBalances])

  const [checkedStates, setCheckedStates] = useState<CheckedStates>({})

  const onClickArrow = async (e: React.MouseEvent<HTMLButtonElement>, balance: Balance) => {
    e.preventDefault()

    if (storedToken) {
      setIsLoadingSocket(true)
      dispatch(switchAccount({ token: storedToken, currencyId: balance.currency_id }))
    }
  }

  const onClickPlus = async (e: React.MouseEvent<HTMLButtonElement>, balance: Balance) => {
    e.preventDefault()
    console.log('onClickPlus', balance)
    setShowModalPlayerDeposit(true)
    setSelectedCurrencyId(balance.currency_id)
  }

  // Тут потом будет реализовано, что будет при нажатии на чекбоксы
  const handleCheckboxChange = (id: number) => {
    setCheckedStates(prevStates => ({
      ...prevStates,
      [id]: !prevStates[id]
    }))
    console.log(`Checkbox with id ${id} clicked`)
  }

  const handleAddWalletClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // e.preventDefault();
    console.log(`handleAddWalletClick`);
    setModalAddWallet(true)
  };

  return (
    <>
      <div>
        <h1>Accounts</h1>
        {/* <GameGroups />
            <Providers /> */}
      </div>

      {listBalancesLoading ? (
        <p>Loading...</p>
      ) : listBalancesError ? (
        <p>Error: {listBalancesError}</p>
      ) :
        <div className="wrap-accounts">
          <div className="row list-accounts">
            {listBalances === null || listBalances.length === 0 ? (
              <strong>No data!</strong>
            ) : (
              listBalances.map((balance, i) => (
                <div key={i} className="col-4">
                  <div className="card">
                    <div className="card-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between">
                          <div className="d-flex justify-content-between">
                            <input
                              type="checkbox"
                              id={`checkbox-${balance.id}`}
                              checked={checkedStates[balance.id] || false}
                              onChange={() => handleCheckboxChange(balance.id)}
                            />
                            <h4 className="card-title" style={{ paddingLeft: '10px' }}>
                              {balance.flag_main === 'YES' ? 'Active' : 'Disabled'}
                            </h4>
                          </div>
                          <div className="">
                            <div className="bonus-card-buttons d-flex justify-content-between">
                              {balance.flag_main === 'NO' && <SquareButton
                                variant="contained"
                                color="primary"
                                onClick={(e) => onClickArrow(e, balance)}
                                className={styles.button}
                                disabled={isLoadingAccountSwitch || isLoadingSocket}
                              >
                                <ArrowDownwardIcon />
                              </SquareButton>
                              }
                              <button
                                type="button"
                                className="btn btn-warning pl-2"
                                onClick={(e) => onClickPlus(e, balance)}
                              >
                                <span className="fa fa-plus"></span>
                              </button>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex justify-content-between">
                            <span>Currency</span>
                            <span>{balance.currency_title}</span>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex justify-content-between">
                            <span>Real</span>
                            <span>{balance.amount}</span>
                          </div>
                        </li>
                        {/* Бонусы пока отсутствуют. */}
                        <li className="list-group-item">
                          <div className="d-flex justify-content-between">
                            <span>Total</span>
                            <span>{balance.amount}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  { }
                </div>
              ))
            )}
          </div>

          <div className="mt-4 add-wallet">
            <button
              type="button"
              onClick={(e) => handleAddWalletClick(e)}
              className="btn btn-success rounded-pill"
            // disabled={setDepositRequestLoading}
            >
              Add wallet
              {/* {setDepositRequestLoading ? 'Processing...' : 'Add wallet'} */}
            </button>
          </div>
        </div>
      }
      {(isLoadingAccountSwitch || isLoadingSocket) && <LinearProgress className={styles.linearProgress} />}

      <ModalPlayerDeposit
        isShowModal={showModalPlayerDeposit}
        onCloseModal={() => setShowModalPlayerDeposit(false)}
        currencyId={selectedCurrencyId}
      />
      
      <ModalAddWallet
        isShowModal={showModalAddWallet}
        onCloseModal={() => setModalAddWallet(false)}
      />

    </>
  )
}

// export default withAuth(Accounts)
export default Accounts
