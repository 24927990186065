// updatePersonalInfoSelectors.js

export const selectUpdatePersonalInfoSuccess      = (state) => state.updatePersonalInfoReducer.success;
export const selectUpdatePersonalInfo             = (state) => state.updatePersonalInfoReducer.result;
export const selectUpdatePersonalInfoMessages     = (state) => state.updatePersonalInfoReducer.resultMessages;
export const selectUpdatePersonalInfoLoading      = (state) => state.updatePersonalInfoReducer.loading;
export const selectUpdatePersonalInfoError        = (state) => state.updatePersonalInfoReducer.error;
export const selectUpdatePersonalInfoErrorMessage = (state) => state.updatePersonalInfoReducer.errorMessage;

export const selectUpdatePersonalInfoErrorArray = (state) => {
    const error = state.updatePasswordReducer.error;
    let errorsArray = [];
  
    if (Array.isArray(error)) {
      errorsArray = error.map((err) => `${err}`);
    } else if (typeof error === 'object' && error !== null) {
      errorsArray = Object.entries(error)
        .flatMap(([key, value]) =>
          Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
        );
    } else if (error) {
      errorsArray = [error];
    }
  
    return errorsArray.length > 0 ? errorsArray : null;
  };