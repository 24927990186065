import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../hooks"
import { Link, useNavigate } from 'react-router-dom';
import PasswordInput from './PasswordInput';
import TextInput from './TextInput';
import Alert from '../../../layouts/ui/alert/Alert';
import {
    fetchUpdatePassword,
    resetState,
    selectUpdatePasswordLoading,
    selectUpdatePasswordSuccess,
    selectUpdatePasswordError,
    selectUpdatePasswordMessages,
    selectUpdatePasswordData,
    selectUpdatePasswordErrorArray
} from 'src/redux/slices/updatePasswordSlice';

import {
    fetchProjectSettings,
    selectProjectSettingsLoading,
    selectProjectSettingsSuccess,
    selectProjectSettingsError,
    selectProjectSettingsMessages,
    selectProjectSettingsData,
    selectProjectSettingsErrorArray
} from 'src/redux/slices/getProjectSettingsSlice';

import { selectIsAuthenticated, selectToken } from '../../../../redux/selectors/site/loginSelectors';

// interface UpdatePasswordState {
//     [key: string]: any; // Ключи — строки, значения могут быть разными
// }

interface UpdatePasswordState {
    current_password: string; // Текущий пароль
    new_password: string;     // Новый пароль
    confirm_password: string; // Подтверждение пароля
}

interface ClientErrors {
    required: { [key: string]: string };
}

const Security = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const updatePasswordLoading = useSelector(selectUpdatePasswordLoading);
    const updatePasswordSuccess = useSelector(selectUpdatePasswordSuccess);
    const updatePasswordError = useSelector(selectUpdatePasswordError);
    const updatePasswordData = useSelector(selectUpdatePasswordData);
    const updatePasswordErrorArray = useSelector(selectUpdatePasswordErrorArray);

    const projectSettingsLoading = useSelector(selectProjectSettingsLoading);
    const projectSettingsSuccess = useSelector(selectProjectSettingsSuccess);
    const projectSettingsError = useSelector(selectProjectSettingsError);
    const projectSettingsData = useSelector(selectProjectSettingsData);
    const projectSettingsErrorArray = useSelector(selectProjectSettingsErrorArray);


    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);
    const [clientErrors, setClientErrors] = useState<ClientErrors>({ required: {} });
    const [isUpdatesSuccess, setIsUpdatesSuccess] = useState<boolean>(false);
    const [showErrors, setShowErrors] = useState(false); //это чтобы алерт с ошибками
    const [fields, setFields] = useState<string[]>([]);

    const [serverValidationErrors, setServerValidationErrors] = useState({
        current_password: null,
        new_password: null,
        confirm_password: null
    });

    const [formState, setFormState] = useState<UpdatePasswordState>({
        current_password: '',
        new_password: '',
        confirm_password: '',
    });

    const [initialState, setInitialState] = useState<UpdatePasswordState>({
        current_password: '',
        new_password: '',
        confirm_password: '',
    });
    // Начальные значения из API
    const [isEditing, setIsEditing] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (isAuthenticated && token) {
            dispatch(fetchProjectSettings({
                token: token,
                params: { category: 'password', code: 'password_display_two_fields' },
            }));

        }
    }, [dispatch, isAuthenticated, token]);

    useEffect(() => {
        console.log('Security projectSettingsData', projectSettingsData)
        let fields = [
            'current_password',
            'new_password',
        ];
        if(projectSettingsData.length > 0 && projectSettingsData[0]['code'] === "password_display_two_fields"){
            if(projectSettingsData[0].value === 'YES'){
                fields.push('confirm_password');
            }
        }
        setFields(fields);
        
    }, [dispatch, projectSettingsData, projectSettingsSuccess]);

    // Результат
    useEffect(() => {
        if (updatePasswordSuccess) {
            setIsUpdatesSuccess(true);
            setFormState(initialState); // Очистка формы после успешной регистрации
            clearValidationStates();
        }
        if (updatePasswordError) {
            setServerValidationErrors({
                current_password: updatePasswordError.current_password || null,
                new_password: updatePasswordError.new_password || null,
                confirm_password: updatePasswordError.confirm_password || null
            });

            setShowErrors(true) // раз ошибки есть то разблокируем показ окна алерта

            console.log('Security updatePasswordError', updatePasswordError);
            console.log('Security updatePasswordErrorArray', updatePasswordErrorArray);
        }

    }, [updatePasswordError, updatePasswordSuccess, updatePasswordErrorArray]);

    const clearStates = () => {
        dispatch(resetState());
        setIsUpdatesSuccess(false);
        setShowErrors(false);
    }

    const clearValidationStates = () => {
        setServerValidationErrors({
            current_password: null,
            new_password: null,
            confirm_password: null
        });
        setClientErrors({ required: {} });
    }

    useEffect(() => {

        // Скидываем сообщения
        return () => {
            // Размонтирование компонента
            console.log('Component unmounted');
            clearStates();
            clearValidationStates();

        };

    }, []);

    const clientValidation = (): boolean => {
        const formElements = formRef.current?.elements;
        const newErrors: ClientErrors = { required: {} };
        let formIsValid = true;

        if (formElements) {
            Array.from(formElements).forEach(field => {
                const input = field as HTMLInputElement;
                if (input.required && !input.value.trim()) {
                    formIsValid = false;
                    const errorMessage = input.getAttribute('data-error-required') || 'This field is required.';
                    newErrors.required[input.name] = errorMessage;
                }
            });
        }

        setClientErrors(newErrors);
        return formIsValid;
    };

    const handleInputChange = (code: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let value = e.target.value;
        console.log(code, value);
        const updatedFormState = {
            ...formState,
            [code]: value,
        };

        setFormState(updatedFormState);

        // Проверяем, изменились ли данные по сравнению с начальным состоянием
        setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState));

        // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
        setClientErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            // Проверка для строки перед использованием `trim()`
            const isFieldFilled = e.target.type === 'checkbox' ? value : (typeof value === 'string' && value.trim() !== '');

            if (isFieldFilled) {
                delete updatedErrors['required'][code];
            } else {
                const errorMessage = e.target.getAttribute('data-error-required') || 'This field is required.';
                updatedErrors['required'][code] = errorMessage;
            }

            return updatedErrors;
        });
    };

    const handleErrorClose = () => {
        setShowErrors(false); // Скрываем ошибки при нажатии на крестик
    };

    const handleButtonClick = () => {
        clientValidation();
    };

    const handleButtonClickClear = () => {
        clearStates();
        clearValidationStates();
        setFormState(initialState);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!token) {
            console.error('Token is missing');
            return; // Прекращаем выполнение, если токен отсутствует
        }

        if (clientValidation()) {
            dispatch(fetchUpdatePassword({ token, params: formState })); // Передаем токен как строку
        } else {
            console.error('Validation failed');
        }
    };



    const formatPlaceholder = (fieldName: string): string => {
        return fieldName
            .replace(/_/g, ' ') // Заменяем `_` на пробел
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Делаем первую букву каждого слова заглавной
    };

    return (

        <>

            <div className="row">
                <div className="col-lg-12">
                    <form onSubmit={handleSubmit} ref={formRef}>

                        {
                            (fields as Array<keyof UpdatePasswordState>).map((nameField, indexField) => (
                                <div className="col-12" key={indexField}>
                                    <TextInput
                                        id={nameField}
                                        name={nameField}
                                        value={formState[nameField] || ''}
                                        onChange={(e) => handleInputChange(nameField, e)}
                                        required
                                        placeholder={formatPlaceholder(nameField)}
                                        // error={clientErrors.required.password || passwordFieldErrors}
                                        error={clientErrors['required'][nameField] || serverValidationErrors[nameField]}
                                    />
                                </div>
                            ))
                        }

                        <button onClick={() => handleButtonClickClear()} type="submit" className="btn btn-secondary me-2" disabled={!isEditing}>
                            Clear All
                        </button>

                        <button
                            onClick={() => handleButtonClick()}
                            type="submit"
                            className="btn btn-primary"
                            disabled={!isEditing || updatePasswordLoading}
                        >
                            {updatePasswordLoading ? 'Loading...' : 'UPDATE PASSWORD'}
                        </button>

                    </form>

                    {isUpdatesSuccess && (
                        <Alert><strong>Success!</strong> Password updated successfuly!</Alert>
                    )}

                    {showErrors && updatePasswordErrorArray && updatePasswordErrorArray.length > 0 && (

                        <Alert onClose={handleErrorClose} type="error">
                            {updatePasswordErrorArray.map((error, index) => {
                                return (<p key={index}>{error}</p>);
                            })}
                        </Alert>
                    )}


                </div>
            </div>

        </>
    )
};

export default Security;
