import React, {ReactNode} from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import styles from './Modal.module.scss'
import x_close from 'src/assets/images/icons/x_close.svg'

interface ModalProps {
  show: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  title?: string | null;
  header?: ReactNode;
  body?: ReactNode;
  children?: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

const Modal: React.FC<ModalProps> = ({
                                       show,
                                       onClose,
                                       title = null,
                                       header,
                                       body,
                                       children,
                                       showHeader = true,
                                       showFooter = false,
                                     }) => {

  if (!show) return null // Если show === false, не рендерим модальное окно

  return (
    // Контент модального окна
    <Dialog
      open={show}
      onClose={onClose}
      classes={{ root: styles.overlay, paper: styles.modalDialog }}
    >
      <button className={styles.btnClose} onClick={onClose}>
       <img src={x_close} alt="x_close"/>
      </button>
      {/* Кнопка закрытия */}
      {(showHeader && (header || title)) && (

        <DialogTitle className={styles.modalHeader}>
          {header || title} {/* Отображаем заголовок */}
        </DialogTitle>
      )}

      <DialogContent className={styles.modalBody}>
        {body || children} {/* Если body отсутствует, будет показан children */}
      </DialogContent>

      {showFooter && (
        <DialogActions className={styles.modalFooter}>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onClose}
          >
            Close
          </button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default Modal
