import React from 'react'
import styles from './ProfileDropdown.module.scss'
import clsx from 'clsx'
import {Avatar} from "../../../common/Icons/User01Icon/Avatar"
import CopyToClipboard from "react-copy-to-clipboard"
import copy_06 from "src/assets/images/icons/copy_06.svg"
import Tippy_up from "src/assets/images/icons/Tippy_up.svg"
import {useSelector} from "react-redux"
import {useGetPersonalInfoQuery} from "../../../api/api"
import {selectToken} from "../../../redux/selectors/site/loginSelectors"
import {Link} from "react-router-dom"


type Props = {
  showDropdown: boolean
  setShowDropdown: (value: boolean) => void
}


export const ProfileDropdown = ({
                                    showDropdown,
                                    setShowDropdown,
                                  }: Props) => {


  const token = useSelector(selectToken)

  const {data: getPersonalInfoData} = useGetPersonalInfoQuery({ token: token ? token : ''})

  const playerId = getPersonalInfoData?.data?.player_id

  const ProfileDropdownLink = ({to}: {to: string}) => <Link to={to} className={styles.link} onClick={() => setShowDropdown(false)}>My profile</Link>

  return (
    <div className={styles.component}>
      {showDropdown && <img src={Tippy_up} alt="Tippy_up" className={styles.tippy}/>}
      <Avatar className={styles.userIcon} style={{cursor: 'pointer'}} onClick={() => setShowDropdown(!showDropdown)}/>
      <div
        className={clsx(
          "dropdown-menu" + (showDropdown ? " show" : ""),
          styles.menu
        )}
      >
        <div className={styles.playerContainer}>
          <Avatar className={styles.userIcon}/>
          <div className={styles.playerData}>
            <div className={styles.name}>{getPersonalInfoData?.data?.login}</div>
            <div className={styles.playerContainer}>
              <div className={styles.player}>Player ID</div>
              <div className={styles.playerID}>{playerId}</div>
              <CopyToClipboard text={playerId ? playerId : ''}>
                <img src={copy_06} alt="copy_06" className={styles.copy}/>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className={styles.linksContainer}>
          <ProfileDropdownLink to={'/personal-information'}/>
          <ProfileDropdownLink to={'/'}/>
          <ProfileDropdownLink to={'/transactions'}/>
        </div>
      </div>
    </div>
  )
}
