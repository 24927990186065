import React, { useState, useRef, useEffect, FormEvent, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks";
import { selectIsAuthenticated, selectToken } from '../../../redux/selectors/site/loginSelectors';
import styles from '../PlayerDeposit.module.scss';
import TextInput from './TextInput';
import {
    fetchListAvailableBalances,
    selectListAvailableBalancesLoading,
    selectListAvailableBalancesSuccess,
    selectListAvailableBalancesError,
    selectListAvailableBalancesMessages,
    selectListAvailableBalancesData

} from '../../../redux/slices/listAvailableBalancesSlice'



interface Currency {
    id: number;
    type: string;
    title: string;
    code: string;
    symbol: string | null;
    flag_active: "YES" | "NO";
    flag_active_players: "YES" | "NO";
    pos: number;
}

// interface ListBalances {
//     fiat?: Currency[]; // Массив для "fiat", может отсутствовать
//     crypto?: Currency[]; // Массив для "crypto", может отсутствовать
// }

interface ListBalances {
    [key: string]: Currency[]; // Ключ — строка, значение — массив объектов Currency
}


interface AddWalletProps {

}


const AddWallet: React.FC<AddWalletProps> = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    const listAvailableBalancesLoading = useSelector(selectListAvailableBalancesLoading);
    const listAvailableBalancesSuccess = useSelector(selectListAvailableBalancesSuccess);
    const listAvailableBalancesError = useSelector(selectListAvailableBalancesError);
    const listAvailableBalancesMessages = useSelector(selectListAvailableBalancesMessages);
    const listAvailableBalancesData = useSelector(selectListAvailableBalancesData);

    // const [activeButton, setActiveButton] = useState<number>(1);

    // const handleButtonClick = (id: number) => {
    //     setActiveButton(id); // Устанавливаем активную кнопку
    // };

    const [searchWallets, setSearchWallets] = useState<string>("");
    const [listBalances, setListBalances] = useState<null | ListBalances>(null);
    const [filteredBalances, setFilteredBalances] = useState<null | ListBalances>(null);
    const [selectedCurrencyId, setSelectedCurrencyId] = useState<null | number>(null);

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('handleFormSubmit');

    };

    useEffect(() => {
        resetStates();
        if (isAuthenticated && token) {
            dispatch(fetchListAvailableBalances({ token }));  // Вызов экшена для получения данных
        }

        console.log('AddWallet: fetchListAvailableBalances');
    }, [dispatch, token, isAuthenticated]);


    useEffect(() => {
        if (listAvailableBalancesSuccess && listAvailableBalancesData) {
            setListBalances(listAvailableBalancesData);
            setFilteredBalances(listAvailableBalancesData);
        } else if (listAvailableBalancesError) {
            // Если была ошибка, можно добавить обработку
            // setShowErrors(true);
            console.error("Error fetching balances");
        }
    }, [listAvailableBalancesSuccess, listAvailableBalancesData, listAvailableBalancesError]);


    useEffect(() => {
        if (listBalances) {
            if (searchWallets.trim() !== "") {
                // Фильтрация по полю 'code'
                const filteredData = Object.entries(listBalances).reduce((acc, [key, currencies]) => {
                    const filteredCurrencies = currencies.filter((currency: Currency) =>
                        currency.code.toLowerCase().includes(searchWallets.toLowerCase())
                    );

                    if (filteredCurrencies.length > 0) {
                        acc[key] = filteredCurrencies;
                    }

                    return acc;
                }, {} as ListBalances);

                setFilteredBalances(filteredData); // Обновляем состояние с фильтрованными данными
            } else {
                // Если поле поиска пустое, отображаем весь список
                setFilteredBalances(listBalances);
            }
        }
    }, [searchWallets, listBalances]);

    useEffect(() => {
        console.log('AddWallet selectedCurrencyId', selectedCurrencyId);
    }, [selectedCurrencyId])

    const resetStates = () => {
        setSearchWallets("");
        setSelectedCurrencyId(null);
    };
    
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchWallets(value);
    };

    const handleCurrencyButtonClick = (currency: Currency) => {
        console.log('AddWallet handleCurrencyButtonClick', currency.id);
        setSelectedCurrencyId(currency.id);
    };

    const renderFormField = () => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e);

        const nameItem = "search-wallets";
        let isRequired = false;
        // <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" class="DocSearch-Search-Icon"><path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path></svg>

        const commonProps = {
            id: nameItem,
            name: nameItem,
            value: searchWallets,
            onChange: handleChange,
            required: isRequired,
            label: "",
            placeholder: "Search currency",
            error: ""
        };

        return <TextInput {...commonProps} />;
    };

    const renderButtons = () => {
        return (
            <>
                <section id="add-wallet-currency-list" className="my-4">
                    {listAvailableBalancesLoading && "loading..."}

                    {listAvailableBalancesSuccess &&
                        filteredBalances &&
                        Object.entries(filteredBalances).map(([key, currencies]) => (
                            <div key={key} className="my-4 add-wallet-currency-list_block">
                                <h5>{key === "fiat" ? "Fiat Currency" : "Crypto Currency"}</h5>
                                <div className="row g-2">
                                    {currencies.map((currency: Currency) => (
                                        <div key={currency.id} className="col-3">
                                            <button
                                                onClick={() => handleCurrencyButtonClick(currency)}
                                                type="button"
                                                className="btn btn-outline-secondary rounded-pill w-100"
                                            >
                                                {currency.code}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                </section>
            </>
            
        );
    };

    return (
        <div>
            <form onSubmit={handleFormSubmit} ref={formRef}>
                {renderFormField()}
            </form>

            {renderButtons()}
        </div>
    )
};

export default AddWallet;
