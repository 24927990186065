// interfaceSlice.ts
import {createSlice} from '@reduxjs/toolkit'
import {MenuItemWithoutChildren} from "../../api/types"

type LanguageState = {
  childrenMenu: {
    isChildrenMenuOpened: boolean
    menuPosition: number
    selectedMenu: MenuItemWithoutChildren[] | null
  }
  selectedLanguage: {
    title: string
    iso_code: string
    id: number
  }
  sidebarHeight: number
  isListBalancesDropdownShown: boolean
  isBalanceLoadingAfterSwitch: boolean
}

const initialState: LanguageState = {
  selectedLanguage: JSON.parse(localStorage.getItem('selectedLanguage') || '{"title": "English", "iso_code": "en", "id": 1}'),
  childrenMenu: {
    isChildrenMenuOpened: false,
    menuPosition: 0,
    selectedMenu: null,
  },
  sidebarHeight: 0,
  isListBalancesDropdownShown: false,
  isBalanceLoadingAfterSwitch: false, // this is for MoneyBlock & ListBalancesDropdown
}

const interfaceSlice = createSlice({
  name: 'interface',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload
      localStorage.setItem('selectedLanguage', JSON.stringify(action.payload))
    },
    setIsChildrenMenuOpened: (state, action) => {
      state.childrenMenu.isChildrenMenuOpened = action.payload
    },
    setChildrenMenuPosition: (state, action) => {
      state.childrenMenu.menuPosition = action.payload
    },
    setSelectedMenu: (state, action) => {
      state.childrenMenu.selectedMenu = action.payload
    },
    clearChildrenMenuData: (state) => {
      state.childrenMenu.isChildrenMenuOpened = false
      state.childrenMenu.menuPosition = 0
      state.childrenMenu.selectedMenu = null
    },
    setSidebarHeight: (state, action) => {
      state.sidebarHeight = action.payload
    },
    setListBalancesDropdownShown: (state, action) => {
      state.isListBalancesDropdownShown = action.payload
    },
    setBalanceLoadingAfterSwitch: (state, action) => {
      state.isBalanceLoadingAfterSwitch = action.payload
    },
  },
  selectors: {
    interfaceSelector: state => state,
    childrenMenuSelector: state => state.childrenMenu
  }
})

export const {
  setLanguage,
  setIsChildrenMenuOpened,
  setChildrenMenuPosition,
  setSelectedMenu,
  clearChildrenMenuData,
  setSidebarHeight,
  setListBalancesDropdownShown,
  setBalanceLoadingAfterSwitch,
} = interfaceSlice.actions

export const {interfaceSelector, childrenMenuSelector} = interfaceSlice.selectors


export const InterfaceReducer = interfaceSlice.reducer

