// balancessActions.js
import axios from 'axios';
import config from '../../config';
import {setBalanceLoadingAfterSwitch} from "../slices/interfaceSlice";

export const FETCH_BALANCES_REQUEST = 'FETCH_BALANCES_REQUEST';
export const FETCH_BALANCES_SUCCESS = 'FETCH_BALANCES_SUCCESS';
export const FETCH_BALANCES_FAILURE = 'FETCH_BALANCES_FAILURE';

// Экшены для получения баланса
export const fetchBalancesRequest = () => ({
  type: FETCH_BALANCES_REQUEST,
});

export const fetchBalancesSuccess = (balances) => ({
  type: FETCH_BALANCES_SUCCESS,
  payload: balances,
});

export const fetchBalancesFailure = (error) => ({
  type: FETCH_BALANCES_FAILURE,
  payload: error,
});

export const fetchBalances = (token) => async (dispatch) => {
  dispatch(fetchBalancesRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/player/balances`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      }
    });
    
    dispatch(fetchBalancesSuccess(response.balances.data));
  } catch (error) {
    dispatch(fetchBalancesFailure(error.message));
  }
};

// Экшены для WebSocket
export const connectWebSocket = (token) => {
  return (dispatch) => {
    const ws = new WebSocket(`${config.serverWebsocketUrl}`);

    ws.onopen = () => {
      console.log('Connected to WebSocket server getBalances');
      ws.send(JSON.stringify({ type: 'getBalances', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getBalances:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.type === 'getBalances') {
          console.log('Balances received:', data.balances.data);
          dispatch(setBalanceLoadingAfterSwitch(false))
          dispatch(fetchBalancesSuccess(data.balances.data));
        } else if (data.type === 'error') {
          dispatch(fetchBalancesFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
