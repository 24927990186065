import React from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import styles from './BrightBreadcrumbs.module.scss'

type BreadcrumbItem = {
  label: string
  path?: string
  isLink?: boolean
}

type BreadcrumbsProps = {
  items: BreadcrumbItem[]
}

export const BrightBreadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumbs
      className={styles.breadcrumbs}
      aria-label="breadcrumb"
      separator={<div className={styles.separator}>/</div>}
    >
      {items.map((item, index) =>
        item.isLink && item.path ? (
          <Link key={index} to={item.path} className={styles.link}>
            {item.label}
          </Link>
        ) : (
          <div key={index} className={styles.categoryNameBreadcrumb}>
            {item.label}
          </div>
        )
      )}
    </Breadcrumbs>
  )
}

