//ModalPlayerDeposit.js
import React, { useState, useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks"
import PlayerDeposit from "../../pages/PlayerDeposit/PlayerDeposit"
import Modal from "../ui/modal/Modal"
import {selectPreviousView} from '../../../redux/selectors/playerDeposit/viewSelectors'
import {setPreviousView} from '../../../redux/actions/playerDeposit/viewActions'
import stylesModal from '../ui/modal/Modal.module.scss'

interface ModalPlayerDepositProps {
    isShowModal: boolean;
    onCloseModal: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
    currencyId?: number | null;
}


const ModalPlayerDeposit: React.FC<ModalPlayerDepositProps> = ({isShowModal, onCloseModal, currencyId})  => {

    const dispatch = useAppDispatch()
    const previousView = useSelector(selectPreviousView)

    const handleModalBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(setPreviousView())
    }
    
    return (

        <>
            <Modal
                show={isShowModal}
                onClose={onCloseModal}
                header={
                    previousView && (
                        <button className={stylesModal.btnPrev} onClick={handleModalBackClick}>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </button>
                    )
                }
                body={isShowModal && <PlayerDeposit propCurrencyId={currencyId}/>} // Ваш компонент PlayerDeposit
            />
        </>
    );
};

export default ModalPlayerDeposit;
