import React from 'react'
import {CommonBlocks} from "../../layouts/CommonBlocks"

const Home = () => {
    return (
        <div>
            <CommonBlocks />
        </div>
    );
};

export default Home;