import React from 'react'
import user_01 from 'src/assets/images/icons/user_01.svg'
import styles from "./User01Icon.module.scss"
import clsx from 'clsx'

type Props = {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties;
}

export const Avatar = ({className, onClick, style}: Props) => {
  return (
    <div className={clsx(styles.icon, className)} onClick={onClick} style={style}>
      <img src={user_01} alt="user_01"/>
    </div>
  )
}