// platform-client-app\react-platform\src\redux\actions\site\registrationActions.js

import axios from 'axios';
import config from '../../../config';

export const ACTION_ACCAUNT_REGISTRATION_REQUEST = 'ACTION_ACCAUNT_REGISTRATION_REQUEST';
export const ACTION_ACCAUNT_REGISTRATION_SUCCESS = 'ACTION_ACCAUNT_REGISTRATION_SUCCESS';
export const ACTION_ACCAUNT_REGISTRATION_FAILURE = 'ACTION_ACCAUNT_REGISTRATION_FAILURE';
export const ACTION_ACCAUNT_REGISTRATION_CLEAR_STATE = 'ACTION_ACCAUNT_REGISTRATION_CLEAR_STATE';

export const actionAccauntRegistrationRequest = () => ({
    type: ACTION_ACCAUNT_REGISTRATION_REQUEST,
});


export const actionAccauntRegistrationSuccess = (data, messages) => ({
    type: ACTION_ACCAUNT_REGISTRATION_SUCCESS,
    payload: { data, messages },
});

export const actionAccauntRegistrationFailure = (error) => ({
    type: ACTION_ACCAUNT_REGISTRATION_FAILURE,
    payload: { error },
});

export const actionAccauntRegistrationClearState = () => ({
    type: ACTION_ACCAUNT_REGISTRATION_CLEAR_STATE,
});

//For Express
export const fetchAccauntRegistration = (userData = {}) => async (dispatch) => {

    // Инициализируем запрос
    dispatch(actionAccauntRegistrationRequest());

    try {
        console.log('Attempting to fetchAccauntRegistration:' + `${config.serverUrl}/site/registration`, { userData });

        const response = await axios.post(`${config.serverUrl}/site/registration`,
            { userData },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

        const data = response.data;
        if (response.status === 200 && data.success) {
            console.log('fetchAccauntRegistration: ', data, data.data, data.messages);
            dispatch(actionAccauntRegistrationSuccess(data.data, data.messages));
        } else {
            console.error('fetchAccauntRegistration error:', data.errors);
            dispatch(actionAccauntRegistrationFailure(data.errors, data.message || 'Error accaunt registration.'));
        }
    } catch (error) {
        const defaultErrorMessage = 'Error account registration.';
        const networkErrorMessage = 'Network error: Unable to connect to the server.';

        if (error.response) {
            console.error(`Error: ${error.response.status}`, error.response.data);

            const { status, data } = error.response;
            const { errors, message, error: singleError } = data;

            if (status === 404) {
                dispatch(actionAccauntRegistrationFailure(null, 'API endpoint not found.'));
            } else if ([400, 403, 500].includes(status)) {
                dispatch(actionAccauntRegistrationFailure(errors || singleError, message || defaultErrorMessage));
            } else {
                dispatch(actionAccauntRegistrationFailure(singleError, message || 'Server error.'));
            }
        } else {
            console.error('fetchAccauntRegistration network error:', error.message);
            dispatch(actionAccauntRegistrationFailure(null, networkErrorMessage));
        }
    }
};

