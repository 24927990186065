import React from 'react'
import notification from 'src/assets/images/icons/notification.svg'
import styles from './Notification.module.scss'

type Props = {
  onClick?: () => void
}

export const Notification = ({onClick}: Props) => {
  return (
    <div className={styles.notification} onClick={onClick}>
      <img src={notification} alt="notification" />
    </div>
  )
}