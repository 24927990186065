import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import GameList from '../../elems/GameList/GameList'
import {selectIsAuthenticated, selectToken} from '../../../redux/selectors/site/loginSelectors'
import {GameItem} from "../../../common/types"
import styles from "./GameGroup.module.scss"
import {ScrollButton} from "../../../common/componentsStyled/ScrollButton/ScrollButton"
import {useGetGameListQuery} from "../../../api/api"
import {Pagination} from "../../../common/Pagination"
import {GAME_GROUP_GAMES_PER_PAGE, PAGE_PAGINATION_VALUES, SIBLING_COUNT} from "../../../common/constants"
import {BrightBreadcrumbs} from "../../../common/BrightBreadcrumbs/BrightBreadcrumbs"
import {addFavoriteGame} from "../../../redux/slices/addFavoriteGame"
import {useAppDispatch} from "../../../hooks"

export const GameGroup: React.FC = () => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(GAME_GROUP_GAMES_PER_PAGE)

  let {gameGroupSlug} = useParams<{ gameGroupSlug: string }>()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const navigate = useNavigate()

  const dispatch = useAppDispatch()


  const {data: gameListData, isLoading, isError, refetch} = useGetGameListQuery(
    {
      token: token ? token : '',
      gameGroupSlug: gameGroupSlug ? gameGroupSlug : '',
      page: currentPage,
      pageSize: itemsPerPage,
    }
  )
  const handleStarClick = async (gameId: number) => {
    if (token) {
      await dispatch(addFavoriteGame({gameId, token}))
      refetch()
    }
  }

  const handlePlayClick = (game: GameItem) => {
    navigate(`/games/${game.slug}`)
  }

  const handleDemoClick = (game: GameItem) => {
    navigate(`/games/${game.slug}?mode=demo`)
  }

  const breadcrumbItems = [
    { label: 'Home page', path: '/', isLink: true },
    { label: 'Games', path: '/games', isLink: true },
    { label: gameGroupSlug || '', isLink: false }
  ]

  if (isLoading) return <div>Loading...</div>
  if (!gameListData) return <></>
  if (isError) return <div>GameGroups error...</div>
  
  return (
    <div className={styles.gameGroupContainer}>
      <BrightBreadcrumbs items={breadcrumbItems} />
      <div className={styles.gameGroup}>
        <div className={styles.nameContainer}>
          <ScrollButton onClick={() => {
            navigate(-1)
          }} direction={"left"}/>
          <div className={styles.categoryName}>{gameGroupSlug}</div>
        </div>
        <GameList
          gameList={gameListData.data}
          onStarClick={handleStarClick}
          onDemoClick={handleDemoClick}
          onPlayClick={handlePlayClick}
        />
        { !(gameListData.pagination.total_count < itemsPerPage) &&
          <div className={styles.paginationContainer}>
            <Pagination
              variant="games"
              onPageChange={(value) => setCurrentPage(Number(value))}
              totalCount={gameListData.pagination.total_count}
              currentPage={currentPage}
              pageSize={itemsPerPage}
              siblingCount={SIBLING_COUNT}
              selectSettings={{
                value: itemsPerPage.toString(),
                onChangeOption: (value: string) => {
                  setItemsPerPage(Number(value))
                  setCurrentPage(1)
                },
                arr: PAGE_PAGINATION_VALUES.map((val) => val.toString()),
              }}
              isSelectorOfPagesHidden={true}
            />
          </div>
        }
      </div>
    </div>
  )
}

 /*todo: add portal functionality & SnackBar if game already in favorites */

