import React from 'react'
import clsx from "clsx"
import styles from './ChildrenMenu.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {childrenMenuSelector, setIsChildrenMenuOpened} from "../../../redux/slices/interfaceSlice"
import {NavWithChildrenMenuClose} from "../NavWithChildrenMenuClose/NavWithChildrenMenuClose"
import trophy_2_1 from "../../../assets/images/trophy_2_1.png"
import Tippy from '../../../assets/images/Tippy.svg'

export const ChildrenMenu = () => {
  const {selectedMenu, menuPosition, isChildrenMenuOpened} = useAppSelector(childrenMenuSelector)
  const dispatch = useAppDispatch()

  const handleSidebarMouseLeave = () => {
    dispatch(setIsChildrenMenuOpened(false))
  }

  if (!selectedMenu) return <></>

  return (
    <div
      className={clsx(styles.childrenMenu, {
        [styles.open]: isChildrenMenuOpened,
        [styles.closed]: !isChildrenMenuOpened,
      })}
      style={{top: `${menuPosition}px`}}
      onMouseLeave={handleSidebarMouseLeave}
    >
      {selectedMenu && (
        <div className={clsx(stylesCommon.itemsContainer, styles.childrenItemsContainer)}>
          <img src={Tippy} alt={'Tippy'} className={styles.tippy}/>
          {selectedMenu.map((item) => (
            <div key={item.id} className={stylesCommon.item}>
              <img src={trophy_2_1} alt="trophy_2_1" className={stylesCommon.trophy}/>
              <NavWithChildrenMenuClose item={item}/>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

// todo: fix right border of Tippy
// todo: add Tippy for light theme

