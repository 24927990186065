import React, {useEffect} from 'react'
import {Box} from '@mui/material'
import {useCarouselScroll} from './useCarouselScroll'
import styles from './BrightCarousel.module.scss'
import clsx from 'clsx'
import {ScrollButton} from "../componentsStyled/ScrollButton/ScrollButton"

type Props = {
  elements: React.ReactNode[]
  isControlHidden?: boolean
  interval?: number  // for autoscroll: in seconds for step-based scroll, or in pixels for smooth scroll
  smooth?: {
    active: boolean
    direction: 'left' | 'right'
  }
  className?: string // className for container styling
}


const BrightCarousel: React.FC<Props> = ({elements, isControlHidden = false, interval, smooth, className}) => {
  const {scrollRef, scroll, startSmoothScroll, stopSmoothScroll} = useCarouselScroll(smooth?.active, smooth?.direction, interval)

  const elementsToRender = smooth
    ? Array.from({length: 4}, () => elements).flat() // Cloning elements 4 times
    : elements

  useEffect(() => {
    if (smooth?.active) {
      startSmoothScroll()
      return () => stopSmoothScroll()
    } else if (interval) {
      const autoScroll = setInterval(() => {
        scroll('right')
      }, interval * 1000)
      return () => clearInterval(autoScroll)
    }
  }, [interval, scroll, smooth, startSmoothScroll, stopSmoothScroll])

  return (
    <div className={styles.brightCarousel}>
      {!isControlHidden &&
        <ScrollButton onClick={() => scroll('left')} direction={'left'} className={styles.scrollButtonLeft}/>
      }
      <Box ref={scrollRef} display="flex" overflow="auto" whiteSpace="nowrap" className={clsx(styles.scrollContainer, className)}>
        {elementsToRender.map((item, index) => (
          <div key={index}>
            {item}
          </div>
        ))}
      </Box>
      {!isControlHidden &&
        <ScrollButton onClick={() => scroll('right')} direction={'right'} className={styles.scrollButtonRight}/>
      }
    </div>
  )
}

export default BrightCarousel

//todo: fix dragging on smooth scroll