import React from "react"
import { Tabs, Tab } from "@mui/material"
import styles from "./BrightTabs.module.scss"
import clsx from "clsx"

type TabItem = {
  label: string
}

type Props = {
  tabs: TabItem[]
  activeTab: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  className?: string
}

export const BrightTabs: React.FC<Props> = ({ tabs, activeTab, onChange, className }) => {
  return (
    <div className={clsx(styles.tabsContainer, className)}>
      <Tabs
        value={activeTab}
        onChange={onChange}
        classes={{
          root: styles.tabs, // For the root container Tabs
          indicator: styles.indicator, // Style for indicator
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            className={`${styles.tab} ${activeTab === index ? styles.activeTab : ""}`}
            disableRipple
          />
        ))}
      </Tabs>
    </div>
  )
}