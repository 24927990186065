import React, {useMemo} from 'react'
import styles from './LanguageDropdown.module.scss'
import clsx from 'clsx'
import {useGetLanguagesQuery} from '../../../api/api'
import {Language} from "../../layouts/Navigation/Navigation"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {clearChildrenMenuData, interfaceSelector, setLanguage} from "../../../redux/slices/interfaceSlice"
import check from "src/assets/images/icons/check.svg"



type Props = {
  showDropdownLanguage: boolean
  setShowDropdownLanguage: (value: boolean) => void
}

export const LanguageDropdown = ({
                                   showDropdownLanguage,
                                   setShowDropdownLanguage,
                                 }: Props) => {

  const {data} = useGetLanguagesQuery()
  const {selectedLanguage} = useAppSelector(interfaceSelector)

  const dispatch = useAppDispatch()

  const handleLanguageChange = (lang: Language) => {
    dispatch(clearChildrenMenuData())
    dispatch(setLanguage(lang))
    setShowDropdownLanguage(false)
  }

  const flagSelector = useMemo(() => (iso_code: string) => {
    return (
      <img
        src={`/images/icons/flags/flag_${iso_code}.svg`}
        className={styles.flag}
        alt={`${iso_code} flag`}
      />
    )
  }, [selectedLanguage])

  return (
    <div className={styles.languageContainer} onClick={() => setShowDropdownLanguage(!showDropdownLanguage)}>
      {flagSelector(selectedLanguage.iso_code)}
      <div
        className={clsx(
          "dropdown-menu" + (showDropdownLanguage ? " show" : ""),
          styles.menu
        )}
      >
        {data &&
          Object.entries(data).map(([id, language]) => (
            <div
              key={id}
              className={styles.item}
              onClick={() => {
                handleLanguageChange({
                  title: language.title,
                  iso_code: language.iso_code,
                  id: Number(id),
                })
                setShowDropdownLanguage(false)
              }
              }
            >
              <div className={styles.flagTitle}>
                <div className={styles.flag}>{flagSelector(language.iso_code)}</div>
                {language.title}
              </div>
              {selectedLanguage.iso_code === language.iso_code &&
                  <img src={check} alt="check" className={styles.check}/>}
            </div>
          ))}
      </div>
    </div>
  )
}
