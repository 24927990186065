import React, { useState } from 'react';
import styles from './Alert.module.scss';

// Определяем интерфейс для пропсов компонента Alert
export interface AlertProps {
    type?: 'success' | 'error' | 'warning' | 'info';
    message?: string | null;
    onClose?: () => void;
    showButtomClose?: boolean;
    children?: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({ type = 'info', message = null, onClose, showButtomClose = true, children }) => {
    const [visible, setVisible] = useState<boolean>(true);

    // Закрытие алерта
    const handleClose = () => {
        setVisible(false);
        if (onClose) onClose();
    };

    // Определение стиля для разных типов сообщений
    const alertTypeClass = () => {
        switch (type) {
            case 'success':
                return 'alert-success';
            case 'error':
                return 'alert-danger';
            case 'warning':
                return 'alert-warning';
            default:
                return 'alert-info';
        }
    };

    if (!visible) return null; // Если алерт скрыт, не рендерим компонент

    return (
        <div className={`alert ${alertTypeClass()} alert-dismissible fade show mt-2 ${styles.alertContainer}`} role="alert">
            {message && <p>{message}</p>}
            {children && <p>{children}</p>}
            {showButtomClose && 
                <button type="button" className={`close ${styles.closeButton}`} onClick={handleClose} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            }
            
        </div>
    );
};

export default Alert;

