import React, { useEffect, useState, useRef, FormEvent, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import TextInput from './formComponents/TextInput';
import DataInput from './formComponents/DataInput';
import PasswordInput from './formComponents/PasswordInput';
import SelectInput from './formComponents/SelectInput';
import CheckboxInput from './formComponents/CheckboxInput';
import Alert from '../../layouts/ui/alert/Alert';
import config from '../../../config.js';

import {
    fetchAuthform
} from 'src/redux/actions/construct/authformActions';

import {
    selectAuthform,
    selectAuthformSuccess,
    selectAuthformMessages,
    selectAuthformLoading,
    selectAuthformError,
    selectAuthformErrorString,
    selectAuthformErrorArray
} from '../../../redux/selectors/construct/authformSelectors';

import {
    fetchCodeConfirmation,
    actionCodeConfirmationClearState
} from 'src/redux/actions/util/codeConfirmationActions';

import {
    selectCodeConfirmation,
    selectCodeConfirmationCreatedSuccess,
    selectCodeConfirmationMessages,
    selectCodeConfirmationLoading,
    selectCodeConfirmationError,
    selectCodeConfirmationErrorString,
    selectCodeConfirmationErrorArray
} from '../../../redux/selectors/util/registrationAccauntSelectors';

import {
    fetchGetRegistrationFormSettings,
    actionSetRegistrationFormSettingsId
} from 'src/redux/actions/setting/getRegistrationFormSettingsActions';

import {
    selectGetRegistrationFormSettings,
    selectGetRegistrationFormSettingsMessages,
    selectGetRegistrationFormSettingsLoading,
    selectRegistrationFormSettingsId,
    selectGetRegistrationFormSettingsError,
    selectSettingRegistrationFormElements
} from '../../../redux/selectors/setting/getRegistrationFormSettingsSelectors';

import { fetchRegisterValidateFirstScreen, actionRegisterValidateFirstScreenClearError } from '../../../redux/actions/site/registerValidationFirstScreenAction';
import {
    selectRegisterValidationFirstScreen,
    selectRegisterValidationFirstScreenMessages,
    selectRegisterValidationFirstScreenLoading,
    selectRegisterValidationFirstScreenErrors,
    selectRegisterValidationFirstScreenSuccess
} from '../../../redux/selectors/site/registerValidationFirstScreenSelectors'

import { fetchAccauntRegistration, actionAccauntRegistrationClearState } from '../../../redux/actions/site/registrationActions';
import {
    selectRegistrationAccaunt,
    selectRegistrationAccauntMessages,
    selectRegistrationAccauntLoading,
    selectRegistrationAccauntError,
    selectRegistrationAccauntErrorArray,
    selectRegistrationAccauntCreatedSuccess
} from '../../../redux/selectors/site/registrationAccauntSelectors';

import { selectIsAuthenticated, selectToken } from '../../../redux/selectors/site/loginSelectors';
import styles from "./Auth.module.scss"

// Интерфейсы для состояний формы и ошибок клиента
interface FormState {
    [key: string]: string | boolean;
}

interface ClientErrors {
    required: { [key: string]: string };
}

interface RegistrationFormElement {
    id: string;
    id_form: string;
    code: string;
    name: string;
    flag_displayed: string;
    flag_required: string;
    flag_displayed_readonly: string;
    flag_required_readonly: string;
    pos: string;
}

interface Country {
    country_id: string;
    title_ru: string;
    title_ua: string;
    title_be: string;
    title_en: string;
    title_es: string;
    title_pt: string;
    title_de: string;
    title_fr: string;
    title_it: string;
    title_pl: string;
    title_ja: string;
    title_lt: string;
    title_lv: string;
    title_cz: string;
    iso: string;
}

interface Bonus {
    id: string;
    name: string;
    image_path: string | null;
}

interface CheckedBonus {
    id: string | null;
}

interface Authform {
    bonuses: Bonus[];
}


interface SignupProps {
    handleButtonActiveTabClick: (tab: 'login' | 'signup') => void;
}

// type ValidationErrorKeys = 'email' | 'password' | 'currency';

const Signup: React.FC<SignupProps> = ({ handleButtonActiveTabClick }) => {
    // const dispatch = useDispatch();
    const dispatch = useAppDispatch();

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    // Рефы 
    const formRef = useRef<HTMLFormElement>(null);

    // Навигация
    const navigate = useNavigate();
    const location = useLocation();


    const authform = useSelector(selectAuthform);
    const authformSuccess = useSelector(selectAuthformSuccess);
    const authformMessages = useSelector(selectAuthformMessages);
    const authformLoading = useSelector(selectAuthformLoading);
    const authformError = useSelector(selectAuthformError);
    const authformErrorArray = useSelector(selectAuthformErrorArray);

    // Результаты запроса на герерацию кода подтверждения
    const codeConfirmation = useSelector(selectCodeConfirmation);
    const codeConfirmationCreatedSuccess = useSelector(selectCodeConfirmationCreatedSuccess);
    const codeConfirmationMessages = useSelector(selectCodeConfirmationMessages);
    const codeConfirmationLoading = useSelector(selectCodeConfirmationLoading);
    const codeConfirmationError = useSelector(selectCodeConfirmationError);
    const codeConfirmationErrorString = useSelector(selectCodeConfirmationErrorString);
    const codeConfirmationErrorArray = useSelector(selectCodeConfirmationErrorArray);

    // Результаты запроса на получение настройки формы
    const getRegistrationFormSettings = useSelector(selectGetRegistrationFormSettings);
    const getRegistrationFormSettingsLoading = useSelector(selectGetRegistrationFormSettingsLoading);
    const getRegistrationFormSettingsError = useSelector(selectGetRegistrationFormSettingsError);
    const getRegistrationFormSettingsMessages = useSelector(selectGetRegistrationFormSettingsMessages);
    const registrationFormSettingsId = useSelector(selectRegistrationFormSettingsId);


    // Валидация формы первого экрана
    const registerValidationFirstScreenSuccess = useSelector(selectRegisterValidationFirstScreenSuccess);
    const registerValidationFirstScreenError = useSelector(selectRegisterValidationFirstScreenErrors);
    // const registerValidationFirstScreenLoading = useSelector(selectRegisterValidationFirstScreenLoading);

    // Результаты запроса на регистрацию игрока
    const registrationAccauntData = useSelector(selectRegistrationAccaunt);
    const registrationAccauntLoading = useSelector(selectRegistrationAccauntLoading);
    const registrationAccauntError = useSelector(selectRegistrationAccauntError);
    const registrationAccauntErrorArray = useSelector(selectRegistrationAccauntErrorArray);
    const registrationAccauntMessages = useSelector(selectRegistrationAccauntMessages);
    const registrationAccauntCreatedSuccess = useSelector(selectRegistrationAccauntCreatedSuccess);
    const settingRegistrationFormElements = useSelector(selectSettingRegistrationFormElements);

    // Вспомогательные локальные стэйты
    const [debouncedId, setDebouncedId] = useState<string | null>(registrationFormSettingsId);
    const [formState, setFormState] = useState<FormState>({});
    const [initialState, setInitialState] = useState<FormState>({});
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isRegistrationSuccess, setIsRegistrationSuccess] = useState<boolean>(false);
    const [clientErrors, setClientErrors] = useState<ClientErrors>({ required: {} });
    const [ip, setIp] = useState<string>('');
    const [view, setView] = useState<'stepOne' | 'stepTwo'>('stepOne');
    const [checkedBonus, setCheckedBonus] = useState<string | null>(null); // хранит id бонуса или null
    const [showErrors, setShowErrors] = useState(false); //это чтобы алерт с ошибками не появлялся после закрытия
    const [showConfirmationErrors, setShowConfirmationErrors] = useState(false); // это только ошибки конфирма
    const [firstScreenServerValidationErrors, setFirstScreenServerValidationErrors] = useState({
        email: null,
        password: null,
        currency: null
    });
    // const [firstScreenServerValidationErrors, setFirstScreenServerValidationErrors] = useState<Record<ValidationErrorKeys, string | null>>({
    //     email: null,
    //     password: null,
    //     currency: null,
    // });
    const [secondScreenServerValidationErrors, setSecondScreenServerValidationErrors] = useState<Record<string, string>>({});



    // Сервер при регистрации ожидает такие имена, а для вівода формі приходят другие
    // Поэтому требуется перед отправкой на сервер поменять название
    const prepereParamNameToServer = (name: string) => {
        switch (name) {
            case 'country':
                return 'id_country';
            case 'currency':
                return 'id_currency';
            case 'birth':
                return 'datebirth';
            case 'password':
                return 'new_password';
            // Добавьте другие преобразования по мере необходимости
            default:
                return name; // Если нет необходимости в изменении, возвращаем имя как есть
        }
    };

    // Первоначальная инициализация формы
    useEffect(() => {
        dispatch(actionSetRegistrationFormSettingsId('1'));
        dispatch(fetchAuthform());
        console.log('component useEffect 1');
    }, [dispatch]);

    // Задержка для обновления ID формы
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedId(registrationFormSettingsId);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [registrationFormSettingsId]);

    // Загрузка настроек формы при смене ID
    useEffect(() => {
        if (isAuthenticated && token) {
            navigate('/');
        } else if (debouncedId) {
            console.log('component registrationFormSettingsId:', debouncedId);
            dispatch(fetchGetRegistrationFormSettings(debouncedId));
        }
    }, [dispatch, isAuthenticated, token, debouncedId, navigate]);


    // Данные формы при инициализации.
    useEffect(() => {
        const initialFormValues: FormState = {};

        if (getRegistrationFormSettingsError || getRegistrationFormSettings.length === 0) {
            return;
        }

        getRegistrationFormSettings?.settingRegistrationFormElements.forEach((item: RegistrationFormElement) => {
            if (item.flag_displayed !== "YES") return;
            if (item.code === 'currency') {
                const defaultCurrencyId = getRegistrationFormSettings.defaultCurrency.id;
                initialFormValues['currency'] = defaultCurrencyId;
            } else {
                initialFormValues[item.code] = "";
            }
        });

        console.log('getRegistrationFormSettings.defaultCurrency.id', getRegistrationFormSettings.defaultCurrency.id);
        console.log('initialFormValues', initialFormValues);


        setFormState(initialFormValues);
        setInitialState(initialFormValues);
    }, [getRegistrationFormSettings, getRegistrationFormSettingsError]);

    // useEffect(() => {
    //     console.log('Signup formState', formState)
    //     console.log('Signup getRegistrationFormSettings', getRegistrationFormSettings)
    //     console.log('Signup authform Success', authformSuccess)
    //     console.log('Signup authform', authform)
    //     console.log('Signup authform checkedBonus', checkedBonus)
    // }, [getRegistrationFormSettings, formState, authformSuccess, authform, checkedBonus]);

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
                setIp(response.data.ip);
            } catch (error) {
                console.error('Error fetching IP:', error);
            }
        };

        fetchIp();
    }, []);

    // After request server validation first screen response
    // Результат запроса на валидацию полей первого экрана
    useEffect(() => {
        console.log('registerValidationFirstScreenError', registerValidationFirstScreenError)
        if (registerValidationFirstScreenError) {

            let errors = {
                email: null,
                password: null,
                currency: null
            }

            if (registerValidationFirstScreenError.email) {
                errors.email = registerValidationFirstScreenError.email;
            }

            if (registerValidationFirstScreenError.new_password) {
                errors.password = registerValidationFirstScreenError.new_password;
            }

            if (registerValidationFirstScreenError.id_currency) {
                errors.password = registerValidationFirstScreenError.id_currency;
            }

            setFirstScreenServerValidationErrors(errors);
            console.log('setFirstScreenServerValidationErrors', errors)


        }
        if (registerValidationFirstScreenSuccess) { // Если поля на сервере валидны, генерируем конфирм код
            if (clientValidation()) {
                const confirmationData = {
                    email: formState.email,
                    type: 'auth'
                };
                dispatch(fetchCodeConfirmation(confirmationData))
            }
        }
    }, [registerValidationFirstScreenSuccess, registerValidationFirstScreenError]);

    // Хелпер для очистки ошибок первого шага
    const clearErrorsStepOne = () => {
        dispatch(actionRegisterValidateFirstScreenClearError());
        setFirstScreenServerValidationErrors({
            email: null,
            password: null,
            currency: null,
        });
    }

    // Очистка ошибок валидации первого экрана
    useEffect(() => {
        // Очистка ошибок при каждом монтировании компонента
        clearErrorsStepOne()

        // Очистка ошибок при размонтировании
        return () => {
            clearErrorsStepOne()
        };
    }, []);

    // useEffect(() => {
    //     console.log('firstScreenServerValidationErrors', firstScreenServerValidationErrors)
    // }, [firstScreenServerValidationErrors]);

    // Generate confirmation code response
    // Результат генерации конфирм кода
    useEffect(() => {

        if (codeConfirmationError) {
            setShowConfirmationErrors(true) // раз ошибки есть то разблокируем показ окна алерта
        } else if (codeConfirmationCreatedSuccess) {
            setView('stepTwo') // if code sent ok go to step to screen
            clearErrorsStepOne() // очистим ошибки первого шага
        }
    }, [codeConfirmationCreatedSuccess, codeConfirmationError]);

    // 
    useEffect(() => {

        if (codeConfirmationCreatedSuccess && view === 'stepTwo') {
            dispatch(actionCodeConfirmationClearState());
        }

    }, [codeConfirmationCreatedSuccess, codeConfirmationError, view]);

    // Результат регистрации на втором экране
    useEffect(() => {
        if (registrationAccauntCreatedSuccess) {
            setIsRegistrationSuccess(true);
            setFormState(initialState); // Очистка формы после успешной регистрации
        }
        if (registrationAccauntError) {
            setShowErrors(true) // раз ошибки есть то разблокируем показ окна алерта

            console.log('registrationAccauntError', registrationAccauntError);

            // if(view === 'stepTwo'){
            //     const updateServerValidationErrors = (key: string, value: string) => { setSecondScreenServerValidationErrors(prevState => ({ ...prevState, [key]: value })); };


            //     let key = item.code;
            //     let value = null;
            //     if(registrationAccauntError[item.code]){
            //         value = registrationAccauntError[item.code];
            //     }
            //     updateServerValidationErrors(key, value);
            //     setSecondScreenServerValidationErrors({key: value});
            //     serverValidationErrors = secondScreenServerValidationErrors[item.code];
            // }



        }
    }, [registrationAccauntCreatedSuccess, registrationAccauntError, initialState]);

    const clearDataAndErrorsStepTwo = () => {
        dispatch(actionAccauntRegistrationClearState());
        setIsRegistrationSuccess(false);
        setShowErrors(false);
    }

    // Очищаем данные регистрации после регистрации
    useEffect(() => {
        clearDataAndErrorsStepTwo()

        // Очистка ошибок при размонтировании
        return () => {
            clearDataAndErrorsStepTwo()
        };
    }, []);

    const prepareFormStateForServer = (formState: Record<string, any>) => {
        const preparedFormState: Record<string, any> = {}; // Новый объект для преобразованных данных

        // Проходим по каждому элементу formState
        Object.entries(formState).forEach(([key, value]) => {
            const preparedName = prepereParamNameToServer(key); // Переименовываем ключ, если нужно
            preparedFormState[preparedName] = value; // Добавляем преобразованный ключ и его значение в новый объект
        });

        return preparedFormState;
    };

    // Обработка клика на кнопку Continue на первом экране
    const handleButtonContinueClick = () => {
        if (clientValidation()) {

            // const prepareFormStateForServer = (formState: Record<string, any>) => {
            //     const preparedFormState: Record<string, any> = {}; // Новый объект для преобразованных данных

            //     // Проходим по каждому элементу formState
            //     Object.entries(formState).forEach(([key, value]) => {
            //         const preparedName = prepereParamNameToServer(key); // Переименовываем ключ, если нужно
            //         preparedFormState[preparedName] = value; // Добавляем преобразованный ключ и его значение в новый объект
            //     });

            //     return preparedFormState;
            // };

            const preparedFormState = prepareFormStateForServer(formState);

            dispatch(fetchRegisterValidateFirstScreen(preparedFormState));
        } else {
            console.error('Validation failed');
        }
    }
    // Обработка формы первого экрана
    const handleConfirmationSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

    };

    // Обработка нажатия кнопки регистрации
    const handleButtonCreateAccauntClick = () => {
        clientValidation();
        console.log('handleButtonCreateAccauntClick');
        console.log('handleButtonCreateAccauntClick formState:', formState);
    };

    // Обработка отправки формы на регистрацию на втором экране
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitted(true);

        const updatedFormState = {
            ...formState,
            ip: ip,  // Добавляем IP пользователя в состояние формы
            bonus_id: checkedBonus !== null ? checkedBonus : undefined  // Добавляем ID бонуса что выбрали, если не нулл
        };


        console.log('handleSubmit data:', updatedFormState);

        if (clientValidation()) {  // Проверка валидации формы перед отправкой

            // const prepareFormStateForServer = (formState: Record<string, any>) => {
            //     const preparedFormState: Record<string, any> = {}; // Новый объект для преобразованных данных

            //     // Проходим по каждому элементу formState
            //     Object.entries(formState).forEach(([key, value]) => {
            //         const preparedName = prepereParamNameToServer(key); // Переименовываем ключ, если нужно
            //         preparedFormState[preparedName] = value; // Добавляем преобразованный ключ и его значение в новый объект
            //     });

            //     return preparedFormState;
            // };

            const preparedFormState = prepareFormStateForServer(updatedFormState);

            dispatch(fetchAccauntRegistration(preparedFormState));
        } else {
            console.error('Validation failed');
        }
    };

    // const handleButtonNextStepClick = () => {
    //     if (codeConfirmationCreatedSuccess) {
    //         setView('stepTwo')
    //     }
    // };


    // Функция валидации полей формы
    const clientValidation = (): boolean => {
        const formElements = formRef.current?.elements;
        const newErrors: ClientErrors = { required: {} };
        let formIsValid = true;

        if (formElements) {
            Array.from(formElements).forEach(field => {
                const input = field as HTMLInputElement;
                // Определяем, является ли поле пустым
                const isFieldEmpty = input.type === 'checkbox' ? !input.checked : !input.value.trim();

                if (input.required && isFieldEmpty) {
                    formIsValid = false;
                    const errorMessage = input.getAttribute('data-error-required') || 'This field is required.';
                    newErrors.required[input.name] = errorMessage;
                }
            });
        }

        setClientErrors(newErrors);
        return formIsValid;
    };

    const handleInputChange = (code: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // Используем `checked` для чекбоксов, а `value` для остальных полей
        const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value;

        console.log(code, value);
        const updatedFormState = {
            ...formState,
            [code]: value,  // Используем `code` как ключ, чтобы обновить соответствующее поле
        };
        // console.log(updatedFormState);
        setFormState(updatedFormState);

        // Проверяем, изменились ли данные по сравнению с начальным состоянием
        setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState));

        // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
        setClientErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            // Проверка для строки перед использованием `trim()`
            const isFieldFilled = e.target.type === 'checkbox' ? value : (typeof value === 'string' && value.trim() !== '');

            if (isFieldFilled) {
                delete updatedErrors['required'][code];
            } else {
                const errorMessage = e.target.getAttribute('data-error-required') || 'This field is required.';
                updatedErrors['required'][code] = errorMessage;
            }

            return updatedErrors;
        });
    };

    const handleErrorClose = () => {
        setShowErrors(false); // Скрываем ошибки при нажатии на крестик
    };

    // Закрытие алерта
    const handleConfirmationErrorClose = () => {
        setShowConfirmationErrors(false); // Скрываем ошибки при нажатии на крестик
    };

    // Кнопка закрытия на первом экране
    const handleClickCloseButtonInFirstScreen = () => {
        if (location.key !== 'default') {
            navigate(-1); // Переход на предыдущую страницу, если история есть
        } else {
            navigate('/'); // Переход на главную, если истории нет
        }
    };


    useEffect(() => {
        console.log('registerValidationFirstScreenError', registerValidationFirstScreenError)
        if (registerValidationFirstScreenError) {

            let errors = {
                email: null,
                password: null,
                currency: null
            }

            if (registerValidationFirstScreenError.email) {
                errors.email = registerValidationFirstScreenError.email;
            }

            if (registerValidationFirstScreenError.new_password) {
                errors.password = registerValidationFirstScreenError.new_password;
            }

            if (registerValidationFirstScreenError.id_currency) {
                errors.password = registerValidationFirstScreenError.id_currency;
            }

            setFirstScreenServerValidationErrors(errors);
            console.log('setFirstScreenServerValidationErrors', errors)


        }
        if (registerValidationFirstScreenSuccess) { // Если поля на сервере валидны, генерируем конфирм код
            if (clientValidation()) {
                const confirmationData = {
                    email: formState.email,
                    type: 'auth'
                };
                dispatch(fetchCodeConfirmation(confirmationData))
            }
        }
    }, [registerValidationFirstScreenSuccess, registerValidationFirstScreenError]);

    // Рендеринг полей формы в зависимости от типа
    const renderFormField = (item: RegistrationFormElement) => {
        // if (item.flag_displayed !== "YES") return null;

        const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
            handleInputChange(item.code, e);

        let serverValidationErrors = null;
        if (view === 'stepOne') {
            if (item.code === "email" || item.code === "password" || item.code === "currency") {
                serverValidationErrors = firstScreenServerValidationErrors[item.code];
            }
        } else if (view === 'stepTwo') { // добавляем валидацию в поля на втором экране
            if (registrationAccauntError?.[item.code]) {
                serverValidationErrors = registrationAccauntError[item.code];
                // setSecondScreenServerValidationErrors(prevState => (
                //     { ...prevState, [item.code]: registrationAccauntError[item.code] }
                // ));
            }

            // const updateServerValidationErrors = (key: string, value: string) => { setSecondScreenServerValidationErrors(prevState => ({ ...prevState, [key]: value })); };

            // let key = item.code;
            // let value = null;
            // if(registrationAccauntError[item.code]){
            //     value = registrationAccauntError[item.code];
            // }
            // updateServerValidationErrors(key, value);
            // setSecondScreenServerValidationErrors({key: value});
            // serverValidationErrors = secondScreenServerValidationErrors[item.code];
        }

        const commonProps = {
            id: item.code,
            name: item.code,
            value: String(formState[item.code] || ''),
            onChange: handleChange, // Обработчик для input и select
            required: item.flag_required === "YES",
            placeholder: item.name,
            error: clientErrors.required[item.code] || serverValidationErrors
            // error: clientErrors.required[item.code]
        };

        switch (item.code) {
            case 'currency':
                console.log('Signup formState /renderFormField /currency', formState)
                const currencyOptions = getRegistrationFormSettings.defaultCurrency;
                return <SelectInput {...commonProps} options={[{ id: currencyOptions.id, name: currencyOptions.code }]} />;

            case 'country':
                const countries: Country[] = getRegistrationFormSettings.countries;
                return <SelectInput {...commonProps} options={countries.map((country: Country) => ({ id: country.country_id, name: country.title_ru }))} />;

            case 'terms':
                return (
                    <CheckboxInput
                        {...commonProps}
                        checked={!!formState[item.code]} // Булевое значение для контролируемого чекбокса
                        hint="I agree to the terms and conditions"
                    />
                );

            case 'birth':
                return <DataInput {...commonProps} />;  

            case 'password':
                return (
                    <>
                        <PasswordInput {...commonProps} id="new_password" name="new_password" placeholder="Enter new password" />
                        {(
                            getRegistrationFormSettings?.is_password_confirmation_required
                            &&
                            <PasswordInput {...commonProps} id="confirm_password" name="confirm_password" placeholder="Confirm password" />
                        )}

                    </>
                );

            default:
                return <TextInput {...commonProps} />;
        }
    };

    // Левый блок - 40% ширины
    // checkedBonus
    const defaultBackgroundColor = '#b2b2b2';

    const renderLeftBar = (): JSX.Element => {
        if (authformSuccess && authform) {
            const imgUrl = config.fileServerBaseUrl;
            let backgroundImage = '';
            let title = authform.title;

            // Если выбран бонус и у него есть изображение
            if (checkedBonus !== null) {
                const selectedBonus = authform.bonuses.find((bonus: Bonus) => bonus.id === checkedBonus);
                if (selectedBonus && selectedBonus.image_path) {
                    backgroundImage = `${imgUrl}${selectedBonus.image_path}`;
                } else {
                    // если у бонуса нет картинки
                    backgroundImage = '';
                }
                if (selectedBonus && selectedBonus.name) {
                    title = selectedBonus.name;
                }
            }

            // Определение фонового изображения в зависимости от разрешения экрана (если бонус не выбран)
            if (!backgroundImage) {
                backgroundImage = `${imgUrl}${authform.image_path_desktop}`;
                if (window.matchMedia("(min-width: 1200px)").matches) {
                    backgroundImage = `${imgUrl}${authform.image_path_large_desktop}`;
                } else if (window.matchMedia("(min-width: 768px) and (max-width: 1199px)").matches) {
                    backgroundImage = `${imgUrl}${authform.image_path_tablet}`;
                } else if (window.matchMedia("(max-width: 767px)").matches) {
                    backgroundImage = `${imgUrl}${authform.image_path_mobile}`;
                }
            }

            return (
                <div className="col-12 col-md-5"
                    style={{
                        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
                        backgroundColor: backgroundImage ? 'none' : defaultBackgroundColor,
                        backgroundSize: 'cover',
                        borderRadius: '0.5rem'
                    }}
                >
                    <div className={styles.leftContent}>
                        <h2 className="text-white">{title}</h2>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12 col-md-5"
                    style={{
                        backgroundColor: defaultBackgroundColor,
                        backgroundSize: 'cover',
                        borderRadius: '0.5rem'
                    }}
                >
                    <div className={styles.leftContent}>
                        <h2 className="text-white">Welcome!</h2>
                        <p className="text-white">
                            Quick registration and login. Join us!
                        </p>
                    </div>
                </div>
            );
        }
    };

    const renderBonusButtons = (): JSX.Element => {
        if (authformSuccess && authform) {
            const imgUrl = config.fileServerBaseUrl;

            return (
                <>
                    {authform.bonuses.map((bonus: Bonus) => (
                        <button
                            key={bonus.id}
                            onClick={() => {
                                setCheckedBonus((prev) => prev === bonus.id ? null : bonus.id); // установка состояния
                            }}
                            className={`${styles.btnInBlock} ${checkedBonus === bonus.id ? styles.active : ''}`}
                        >
                            {bonus.image_path ? (
                                <img src={`${imgUrl}${bonus.image_path}`} alt={bonus.name} />
                            ) : (
                                <span className={styles.noImage}>{bonus.name.charAt(0)}</span>
                            )}
                        </button>
                    ))}
                </>
            );
        } else {
            return (<></>);
        }
    };


    // Render View 1
    const renderViewStepOne = (): JSX.Element => (
        <>
            <div className={styles.outsideButtonsBlock} id="button-close-registration">
                <button onClick={handleClickCloseButtonInFirstScreen} className={styles.btnInBlock}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                </button>

                {renderBonusButtons()}

            </div>

            <div className="row">

                {/* Левый блок - 40% ширины */}

                {renderLeftBar()}

                {/* Правый блок - 60% ширины */}
                <div className="col-12 col-md-7">
                    <div className={styles.rightContent}>

                        {/* Блок с кнопками Логин и Регистрация */}
                        <div className="d-flex mb-3">
                            <button onClick={() => handleButtonActiveTabClick('login')} className="btn btn-outline-primary w-50 rounded-3 me-2">Login</button>
                            <button onClick={() => handleButtonActiveTabClick('signup')} className="btn btn-primary w-50 rounded-3">Register</button>
                        </div>

                        {/* Блок с формой */}
                        <form onSubmit={handleConfirmationSubmit} ref={formRef}>
                            <div className="row">
                                {settingRegistrationFormElements && settingRegistrationFormElements.map((item: RegistrationFormElement, index: number) => {
                                    if (item.flag_displayed !== "YES") return null;

                                    if (item.code === "email" || item.code === "password" || item.code === "currency" || item.code === "terms") {
                                        return (
                                            <div className="col-12" key={item.id || index}>
                                                <React.Fragment>
                                                    {renderFormField(item)}
                                                </React.Fragment>
                                            </div>
                                        );
                                    }
                                })}
                            </div>

                            <div key="gender-radio">
                                {getRegistrationFormSettings.flag_gender === "YES" && (
                                    <div className="mb-3">
                                        <label className="form-label"><strong>Gender</strong></label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="genderMale"
                                                value="male"
                                            />
                                            <label className="form-check-label" htmlFor="genderMale">
                                                Male
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="genderFemale"
                                                value="female"
                                            />
                                            <label className="form-check-label" htmlFor="genderFemale">
                                                Female
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div key="subscription-checkbox">
                                {getRegistrationFormSettings?.flag_subscription !== 'YES' && (
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="subscriptionCheck"
                                                name="subscription"
                                                // checked={true}
                                                onChange={(e) => handleInputChange('subscription', e)}
                                            />
                                            <label className="form-check-label" htmlFor="subscriptionCheck">
                                                I want to receive information about news and promotions.
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="row">
                                {/* Блок с кнопкой и ссылкой */}
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-primary w-100 mb-2" onClick={handleButtonContinueClick}>
                                        {codeConfirmationLoading ? ('Loading...') : ('Continue')}
                                    </button>
                                </div>
                            </div>
                        </form>

                        {/* {firstScreenServerValidationErrors.email || firstScreenServerValidationErrors.password && firstScreenServerValidationErrors.currency && (
                            <Alert onClose={handleConfirmationErrorClose} type="error">
                                {firstScreenServerValidationErrors.email && (
                                    <p>{firstScreenServerValidationErrors.email}</p>
                                )}
                                {firstScreenServerValidationErrors.email && (
                                    <p>{firstScreenServerValidationErrors.email}</p>
                                )}
                                {firstScreenServerValidationErrors.email && (
                                    <p>{firstScreenServerValidationErrors.email}</p>
                                )}
                            </Alert>
                        )} */}


                        {showConfirmationErrors && codeConfirmationErrorArray && codeConfirmationErrorArray.length > 0 && (
                            <Alert onClose={handleConfirmationErrorClose} type="error">
                                {codeConfirmationErrorArray.map((error, index) => (
                                    <p key={index}>{error}</p>
                                ))}
                            </Alert>
                        )}


                        {codeConfirmationCreatedSuccess && (
                            <Alert><strong>{codeConfirmationMessages ? codeConfirmationMessages : 'Success!'}</strong></Alert>
                        )}

                        {/* Блок с соцсетями */}
                        <div className="mt-4 text-center">
                            <h6>--- Sign up with social media ---</h6>
                            <div className="d-flex justify-content-between mt-3">
                                <button className="btn btn-outline-secondary flex-fill me-2">Google</button>
                                <button className="btn btn-outline-secondary flex-fill me-2">Facebook</button>
                                <button className="btn btn-outline-secondary flex-fill">Apple</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* тут будет внутренний контент */}
        </>
    );

    // Render View 2
    const renderViewStepTwo = (): JSX.Element => (
        <>
            <div className={styles.outsideButtonsBlock}>
                <button onClick={() => { setView('stepOne') }} className={styles.btnInBlock}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                </button>

                {renderBonusButtons()}

            </div>

            <div className="row">
                {/* Левый блок - 40% ширины */}

                {renderLeftBar()}

                {/* Правый блок - 60% ширины */}
                <div className="col-12 col-md-7">
                    <div className={styles.rightContent}>

                        {/* Блок с кнопками Логин и Регистрация */}
                        <div className="d-flex mb-3">
                            <button onClick={() => handleButtonActiveTabClick('login')} className="btn btn-outline-primary w-50 rounded-3 me-2">Login</button>
                            <button onClick={() => handleButtonActiveTabClick('signup')} className="btn btn-primary w-50 rounded-3">Register</button>
                        </div>

                        {/* Блок с формой */}
                        <form onSubmit={handleSubmit} ref={formRef}>
                            <div className="row">
                                {settingRegistrationFormElements && settingRegistrationFormElements.map((item: RegistrationFormElement, index: number) => {
                                    if (item.flag_displayed !== "YES") return null;

                                    if (item.code === "email" || item.code === "password" || item.code === "currency" || item.code === "terms") {
                                        return null;
                                    }

                                    return (
                                        <div className="col-6" key={item.id || index}>
                                            <React.Fragment>
                                                {renderFormField(item)}
                                            </React.Fragment>
                                        </div>
                                    );
                                })}

                            </div>

                            <div key="gender-radio">
                                {getRegistrationFormSettings.flag_gender === "YES" && (
                                    <div className="mb-3">
                                        <label className="form-label"><strong>Gender</strong></label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="genderMale"
                                                value="male"
                                            />
                                            <label className="form-check-label" htmlFor="genderMale">
                                                Male
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="genderFemale"
                                                value="female"
                                            />
                                            <label className="form-check-label" htmlFor="genderFemale">
                                                Female
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="row">
                                {/* Блок с кнопкой и ссылкой */}
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-primary w-100 mb-2" onClick={handleButtonCreateAccauntClick}>
                                        {registrationAccauntLoading ? 'loading...' : 'Register'}
                                    </button>
                                </div>
                            </div>
                        </form>

                        {showErrors && registrationAccauntErrorArray && registrationAccauntErrorArray.length > 0 && (
                            <Alert onClose={handleErrorClose} type="error">
                                {registrationAccauntErrorArray.map((error, index) => {
                                    return (<p key={index}>{error}</p>);
                                    // Проверяем, существует ли ошибка в локальном состоянии
                                    // if (!secondScreenServerValidationErrors.hasOwnProperty(index)) {
                                    //     return (<p key={index}>{error}</p>);
                                    // }
                                    // return null; // Не выводим ошибку, если она есть в локальном состоянии
                                })}
                            </Alert>
                        )}



                        {isRegistrationSuccess && (
                            <Alert><strong>Success!</strong> Player account has been created! Now you can log in!</Alert>
                        )}


                        {/* Блок с соцсетями */}
                        <div className="mt-4 text-center">
                            <h6>--- Sign up with social media ---</h6>
                            <div className="d-flex justify-content-between mt-3">
                                <button className="btn btn-outline-secondary flex-fill me-2">Google</button>
                                <button className="btn btn-outline-secondary flex-fill me-2">Facebook</button>
                                <button className="btn btn-outline-secondary flex-fill">Apple</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* тут будет внутренний контент */}
        </>
    );

    // -------------------------------
    // Вывод представлений
    // -------------------------------

    // Рендеринг представления страницы
    if (getRegistrationFormSettingsLoading) {
        return <p>loading...</p>;
    }

    if (getRegistrationFormSettingsError) {
        return (
            <div className="alert alert-danger mt-2" role="alert">
                {getRegistrationFormSettingsError || 'An unexpected error occurred.'}
            </div>
        );
    }

    if (!getRegistrationFormSettings || getRegistrationFormSettings.length === 0) {
        return <p>no data...</p>;
    }

    if (getRegistrationFormSettings?.flag_active !== 'YES') {
        return <p>This form is inactive...</p>;
    }

    switch (view) {
        case 'stepOne':
            return (renderViewStepOne())
        case 'stepTwo':
            return (renderViewStepTwo())

        default:
            return null; // Можно вернуть null или какой-то компонент по умолчанию
    }

};

export default Signup;
