// loginActions.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';
export const CLEAR_LOGIN_ERRORS  = 'CLEAR_LOGIN_ERRORS';

export const fetchLoginRequest = () => ({
  type: FETCH_LOGIN_REQUEST,
});

export const fetchLoginSuccess = (token) => {
  // window.localStorage.setItem('token', token);// сохраняю в редюсере

  return {
    type: FETCH_LOGIN_SUCCESS,
    payload: token,
  };
};

export const fetchLoginFailure = (error) => ({
  type: FETCH_LOGIN_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: CLEAR_LOGIN_ERRORS
});


// Обновил код, чтобы лучше обробатывать ошибки от апи
export const fetchLogin = (email, password, ip, navigate) => async (dispatch) => {

  dispatch(fetchLoginRequest());

  try {
    console.log('Attempting to login:' + `${config.serverUrl}/auth/login`, { email, password, ip });

    const response = await axios.post(`${config.serverUrl}/auth/login`, {
      email,
      password,
      ip
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log('Login response:', response);

    if (response.status === 200 && response.data.success) {
      const token = response.data.data.token;
      dispatch(fetchLoginSuccess(token));
      
      const loginState = window.localStorage.getItem('loginState');
      const tok = loginState ? JSON.parse(loginState).token : null;
      console.log('Logined token in localstorage:', tok);

      navigate('/');
    } else {
      console.error('Login failed:', response.data.errors);
      dispatch(fetchLoginFailure(response.data.errors || 'Login failed'));
    }
  } catch (error) {
    if (error.response) {
      // Здесь мы прочитаем сообщение об ошибке с сервера
      console.error(`Login error: ${error.response.status}`, error.response.data);

      // Обрабатываем различные ошибки по статусу
      if (error.response.status === 400) {
        dispatch(fetchLoginFailure(error.response.data.errors || 'Validation error'));
      } else if (error.response.status === 404) {
        dispatch(fetchLoginFailure('API endpoint not found.'));
      } else if (error.response.status === 500) {
        dispatch(fetchLoginFailure(error.response.data.message || 'Internal Server Error'));
      } else {
        dispatch(fetchLoginFailure(`Error ${error.response.status}: ${error.response.data.message || 'An unknown error occurred.'}`));
      }
    } else {
      // Если произошла сетевая ошибка (сервер недоступен, нет соединения и т.п.)
      console.error('Login network error:', error.message);
      dispatch(fetchLoginFailure('Network error: Unable to connect to the server.'));
    }
  }
};