import styles from "./Search.module.scss"
import clsx from "clsx"

type PropsType = {
  inputValue: string
  inputPlaceholder: string
  setInputValue: (value: string) => void
  onKeyUp: () => void
  className?: string
}

export const Search = (props: PropsType) => {
    return (
        <div className={clsx(styles.search, props.className)}>
            <input className={styles.inputSearch}
                   name="name"
                   type="text"
                   value={props.inputValue}
                   placeholder={props.inputPlaceholder}
                   onChange={(e) => props.setInputValue(e.target.value)}
                   onKeyUp={props.onKeyUp}
            />
        </div>
    );
}

