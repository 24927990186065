import React from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import styles from './ButtonStyled.module.scss'

type Props = {
  to?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  children: React.ReactNode;
  className?: string;
  variant?: 'primary' | 'glass';
  height?: string;
  paddingVariant?: '12px 16px' | '14px 18px';
};

export const ButtonStyled = ({
                               to,
                               onClick,
                               children,
                               className = '',
                               variant = 'primary',
                               height = '40px',
                               paddingVariant = '12px 16px',
                             }: Props) => {
  const buttonClass = clsx(styles.button, className, {
    [styles.primary]: variant === 'primary',
    [styles.glass]: variant === 'glass',
  })

  const style: React.CSSProperties = {
    height: height,
    padding: paddingVariant,
  }

  if (to) {
    return (
      <Link to={to} className={buttonClass} style={style}>
        {children}
      </Link>
    )
  }

  return (
    <button className={buttonClass} onClick={onClick} style={style}>
      {children}
    </button>
  )
}
