import React, { forwardRef, useState, MouseEvent } from 'react';
import config from '../../../../config.js';
import styles from './CurrencyAndPayment.module.scss';
import { PaymentMethod, Balance } from '../PlayerDeposit';
import clsx from 'clsx';
import {ButtonStyled} from "../../../../common/componentsStyled/ButtonStyled/ButtonStyled"
import credit_deposit_01 from 'src/assets/images/icons/buttons/credit_deposit_01.svg'
import coins_exchange_01 from 'src/assets/images/icons/buttons/coins_exchange_01.svg'
import credit_withdraw_01 from 'src/assets/images/icons/buttons/credit_withdraw_01.svg'
import currency_buycrypto_01 from 'src/assets/images/icons/buttons/currency_buycrypto_01.svg'
import {currencySelector} from "../../../../common/CurrencySelectors/CurrencySelectors"

// Определяем интерфейсы для пропсов и других типов
interface Currency {
  currency_id: number;
  currency_code: string;
  amount: number;
}

// interface PaymentMethod {
//   id: string;
//   name: string;
//   min_deposit: number;
//   max_deposit: number;
//   image_path?: string;
//   pay_system_name?: string;
// }

interface CurrencyAndPaymentFormProps {
  playerBalances: Currency[];
  getPaymentMethods: PaymentMethod[];
  playerBalancesLoading: boolean;
  playerBalancesErrorString: string | null;
  getPaymentMethodsLoading: boolean;
  getPaymentMethodsErrorString: string | null;
  selectedCurrency: string | null | Balance;
  selectedPaymentMethod?: string | null | PaymentMethod;
  handleCurrencyChange: (currencyId: number) => void;
  handlePaymentMethodChange: (paymentMethodId: string) => void;
  handleButtonContinueClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isButtonContinueClick: boolean;
}

const imgDir = config.fileUrls.payment
const defaultImg = "https://img.freepik.com/premium-vector/content-delivery-network-concept-proxy-anonymous-vpn-vector-design-cloud-processing-computing_135661-1393.jpg?semt=ais_hybrid"

const handleCurrencyMore = (e: MouseEvent<HTMLButtonElement>) => {
  // Ваша логика обработки
}

const CurrencyAndPaymentForm = forwardRef<HTMLDivElement, CurrencyAndPaymentFormProps>(({
                                                                                          playerBalances,
                                                                                          getPaymentMethods,
                                                                                          playerBalancesLoading,
                                                                                          playerBalancesErrorString,
                                                                                          getPaymentMethodsLoading,
                                                                                          getPaymentMethodsErrorString,
                                                                                          selectedCurrency,
                                                                                          selectedPaymentMethod,
                                                                                          handleCurrencyChange,
                                                                                          handlePaymentMethodChange,
                                                                                          handleButtonContinueClick,
                                                                                          isButtonContinueClick
                                                                                        }, ref) => {
  const [activeButton, setActiveButton] = useState<number>(1)

  type ButtonData = {
    id: number;
    label: string;
    img: string;
    imgAlt: string;
  }

  const buttonData: Array<ButtonData> = [
    {id: 1, label: 'Deposit', img: credit_deposit_01, imgAlt: 'credit_deposit_01'},
    {id: 2, label: '', img: credit_withdraw_01, imgAlt: 'credit_withdraw_01'},
    {id: 3, label: '', img: coins_exchange_01, imgAlt: 'coins_exchange_01'},
    {id: 4, label: '', img: currency_buycrypto_01, imgAlt: 'currency_buycrypto_01'},
  ]

  const handleButtonClick = (id: number) => {
    setActiveButton(id) // Устанавливаем активную кнопку
  }

  return (
    <div ref={ref} className={styles.currencyAndPayments}>
      <div className={clsx(styles.navigationButtonsContainer)}>
        <div className={`${styles.buttonGroup} btn-group d-flex`} role="group" aria-label="Basic example">
          {buttonData.map((button) => (
            <ButtonStyled
              key={button.id}
              variant={activeButton === button.id ? 'primary' : 'glass'}
              onClick={() => handleButtonClick(button.id)}
              className={clsx(styles.button, styles.navigationButton)}
            >
              <div className={styles.buttonContent}>
                {button.label} <img src={button.img} alt={button.imgAlt}
                                    className={button.label.length > 0 ? styles.imgWithMargin : ''}/> {/* if button has label, then add margin to img */}
              </div>
            </ButtonStyled>
          ))}
        </div>
      </div>

      <div className={styles.currencyContainer}>
        <div className={styles.currencyElement}>
          <div className={styles.currencyText}>Currency for deposit</div>
          {selectedCurrency && typeof selectedCurrency !== 'string' && (
            <div className={styles.balance}>
              <div className={styles.name}>Balance:</div>
              <div className={styles.amount}>{selectedCurrency.amount} {selectedCurrency.currency_code}</div>
            </div>
          )}
          <div className={`${styles.buttonGroup} btn-group d-flex`} role="group" aria-label="Currency">
            {playerBalances.map((balance) => (
              <button
                key={balance.currency_id}
                type="button"
                onClick={() => handleCurrencyChange(balance.currency_id)}
                className={clsx(styles.currencyButton,
                  typeof selectedCurrency !== 'string' &&
                  selectedCurrency?.currency_id === balance.currency_id &&
                  styles.currencyButtonSelected)
                }
              >
                <div className={styles.currencyCode}>
                  {currencySelector(balance.currency_code)}
                  {balance.currency_code}
                </div>
              </button>
            ))}
          </div>
          {/*<ButtonStyled variant={'glass'} onClick={(e) => handleCurrencyMore(e)}
                        className={clsx(styles.button, styles.moreButton)}>More </ButtonStyled>*/} {/* hidden */}
        </div>

        <div className={styles.currencyElement}>
          <div className={styles.currencyText}>
            Payment methods
          </div>
          {selectedPaymentMethod !== 'string' && selectedPaymentMethod && (
            <div className={styles.balance}>
              <div className={styles.amount}>
                min deposit: {(selectedPaymentMethod as PaymentMethod).min_deposit} | max.
                deposit: {(selectedPaymentMethod as PaymentMethod).max_deposit}
              </div>

            </div>

          )}
          <div className={styles.paymentButtonsGroup} role="group" aria-label="Payment method selection">
            {getPaymentMethodsLoading ? (
              <div>Loading...</div>
            ) : getPaymentMethodsErrorString ? (
              <div>{getPaymentMethodsErrorString}</div>
            ) : (
              <div role="group" aria-label="Payment method selection">
                {getPaymentMethods.map((method) => (
                  <button
                    key={method.id}
                    type="button"
                    onClick={() => handlePaymentMethodChange(method.id)}
                    className={clsx(styles.currencyButton,
                      (selectedPaymentMethod !== 'string' && (selectedPaymentMethod as PaymentMethod)?.id === method.id) && styles.currencyButtonSelected
                    )}
                  >
                    <img
                      src={method.image_path ? `${imgDir}${method.image_path}` : defaultImg}
                      alt={method.pay_system_name}
                      className={styles.paymentMethodImg }
                    />
                  </button>
                ))}
              </div>
            )}
          </div>
          {getPaymentMethods.length > 0 ? (
            <>
              {/*<ButtonStyled variant={'glass'} onClick={(e) => handleCurrencyMore(e)}
                            className={clsx(styles.button, styles.moreButton)}>More </ButtonStyled>*/} {/* hidden */}
            </>
          ) : (
            <p>No data...</p>
          )}
        </div>
      </div>


      {isButtonContinueClick && (!selectedCurrency || !selectedPaymentMethod) && (
        <div className="alert alert-danger mt-2" role="alert">
          <p>Please select both currency and payment method!</p>
        </div>
      )}

      <ButtonStyled variant={'primary'} onClick={(e) => handleButtonContinueClick(e)}
                    className={styles.button}>Continue</ButtonStyled>
      <div className={styles.promoContainer}>
        <div className={styles.promo}>I have promocode</div>
      </div>
    </div>
  )
})

export default CurrencyAndPaymentForm
