import React from 'react'
import {GameItem} from '../../../common/types'
import {GameCard} from "../../../common/GameCard/GameCard"
import {GameInList} from "../../../api/types"
import styles from "./GameList.module.scss"

type GameListProps = {
  gameList: GameInList[]
  onStarClick: (gameId: number) => void
  onDemoClick: (game: GameItem) => void
  onPlayClick: (game: GameItem) => void
}

const GameList: React.FC<GameListProps> = ({gameList, onStarClick}) => {

  if (!gameList?.length) {
    return <p>No games available...</p>
  }

  return (
    <div className={styles.gameList}>
      {gameList.map((item, i) => (
        <GameCard key={i} game={{...item, isFavorite: item.isFavorite === 'true'}} isAuthenticated={true} onStarClick={onStarClick}/>
      ))}
    </div>
  )
}

export default GameList
