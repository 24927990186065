import {useEffect, useMemo} from 'react'
import i18n from './i18n'
import {interfaceSelector} from "../../redux/slices/interfaceSlice"
import {useGetConstructTextBlocksQuery, useGetLanguagesQuery} from "../../api/api"
import {useAppSelector} from "../../hooks"

export const useI18nLanguage = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const {data: languages} = useGetLanguagesQuery()
  const {data: languageData} = useGetConstructTextBlocksQuery({
    language: selectedLanguage.id,
  })

  const languageMap = useMemo(() => {
    if (!languages) return {}; // If language are undefined, return an empty object

    return Object.fromEntries(
      Object.entries(languages).map(([id, lang]) => [Number(id), lang.iso_code])
    );
  }, [languages]);

  useEffect(() => {
    const languageCode = languageMap[selectedLanguage.id]

    if (languageCode) {
      if (languageData?.data) {
        // If translations have arrived, we add them
        i18n.addResources(languageCode, 'translation', languageData.data)
      } else {
        // If translations have not arrived, we clear resources for the language
        i18n.addResources(languageCode, 'translation', {})
      }

      i18n.changeLanguage(languageCode) // Change the current language
    } else {
      console.error('Unknown language ID:', selectedLanguage.id) // Log an error for unknown language ID
    }
  }, [languageData, selectedLanguage.id, languageMap])
}