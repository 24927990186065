import styles from "./Games.module.scss"
import GameWidget from "../../elems/GameWidget/GameWidget"
import React from "react"

export const Games = () => {
  return (
    <div className={styles.games}>
      <GameWidget />

    </div>
  )
}