import React, {useEffect, useRef, useState} from 'react'
import styles from './GameWidget.module.scss'
import clsx from 'clsx'
import {Alert, Portal, Snackbar} from "@mui/material"
import {BrightCarouselDetachedControl, CarouselHandle} from "src/common/BrightCarousel/BrightCarouselDetachedControl"
import {useSelector} from "react-redux"
import {selectIsAuthenticated, selectToken} from "../../../redux/selectors/site/loginSelectors"
import {
  addFavoriteGame,
  addFavoriteGameSelector,
  clearFavoriteGameResponse
} from "../../../redux/slices/addFavoriteGame"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {getGameWidgets, getGameWidgetsSelector} from "../../../redux/slices/getGameWidgets"
import {useGetGameWidgetsQuery} from "../../../api/api"
import {GameWidgetsResponse} from "../../../api/types"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"
import {ButtonStyled} from "../../../common/componentsStyled/ButtonStyled/ButtonStyled"
import {ScrollButton} from "../../../common/componentsStyled/ScrollButton/ScrollButton"
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {CarouselTitleIcon} from "../../../common/CarouselTitleIcon/CarouselTitleIcon"
import trophy_2_1_dummy from "src/assets/images/trophy_2_1_dummy.png"
import {BonusesGroup} from "../BonusesGroup/BonusesGroup"
import {useTranslation} from "react-i18next"
import {GameCard} from "../../../common/GameCard/GameCard"

export const GameWidget = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const {
    data: dataFromRTK,
    isLoading: isLoadingRTK,
    isError: isErrorRTK
  } = useGetGameWidgetsQuery({language: selectedLanguage.id}, {
    skip: isAuthenticated // if user is authenticated, then we skip data fetching via RTK Query without token
  })

  const {t} = useTranslation()

  const token = useSelector(selectToken)
  const dispatch = useAppDispatch()
  const {response} = useAppSelector(addFavoriteGameSelector)

  const {data: dataFromSocket, loading: isLoadingSocket, error: isErrorSocket} = useAppSelector(getGameWidgetsSelector)

  // Array of refs for each slider
  const carouselRefs = useRef<{ scroll: (direction: 'left' | 'right') => void }[]>([])

  // State for Snackbar visibility and message
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null)

  // state for data from socket or rtk query
  const [data, setData] = useState<null | GameWidgetsResponse>(null)

  const onStarClick = async (gameId: number) => {
    if (token) {
      await dispatch(addFavoriteGame({gameId, token}))
      dispatch(getGameWidgets({token, language: selectedLanguage.id}))
    }
  }

  useEffect(() => {
    if (token && isAuthenticated && selectedLanguage) {
      dispatch(clearFavoriteGameResponse())
      dispatch(getGameWidgets({token, language: selectedLanguage.id})) // getting data from server via websocket with token if user isAuthenticated
    }
  }, [token, selectedLanguage])

  useEffect(() => {
    if (isAuthenticated && dataFromSocket) {
      setData(dataFromSocket.gameWidgets)
    } else if (!isAuthenticated && dataFromRTK) {
      setData(dataFromRTK)
    } else {
      setData(null) // that means something went wrong
    }
  }, [isAuthenticated, dataFromRTK, dataFromSocket])

  useEffect(() => {
    if (response && response.messages) {
      setSnackbarMessage(response.messages[0])
      setSnackbarOpen(true)
    }
  }, [response])

  useEffect(() => {
    console.log('dataFromSocket', dataFromSocket)
  }, [dataFromSocket])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  if (isLoadingRTK || (isLoadingSocket && !dataFromSocket)) return <div>Loading GameWidget...</div>
  if (isErrorRTK || isErrorSocket) return <div/>

  return (
    <>
      <div className={clsx(styles.widgetContainer, stylesCommon.group)}>
        {data?.data.map((group, index) => (
          <div key={group.id} className={clsx(styles.groupContainer)}>
            <div className={styles.titleContainer}>
              <div className={styles.titleAndControls}>
                <CarouselTitleIcon logoPath={trophy_2_1_dummy} title={group.title} />
                <div className={styles.sliderControls}>
                  <ScrollButton onClick={() => carouselRefs.current[index]?.scroll('left')} direction={'left'} variant={'arrow'}/>
                  <ScrollButton onClick={() => carouselRefs.current[index]?.scroll('right')} direction={'right'} variant={'arrow'}/>
                </div>
              </div>
              <ButtonStyled to={`/game-group/${group.game_group_slug}`} variant={'glass'}>{t('Show more')}</ButtonStyled>
            </div>

            {/* Display the games within the group using the slider */}
            <div className={styles.gamesContainer}>
              <BrightCarouselDetachedControl
                ref={(el: CarouselHandle | null) => {
                  if (el) {
                    carouselRefs.current[index] = el // Assign ref if element exists
                  }
                }}
                array={group.games.map((game) => (
                  <GameCard key={game.game_id} game={game} isAuthenticated={isAuthenticated} onStarClick={onStarClick}/>
                ))}
                className={styles.carousel}
              />
            </div>
            {index === 1 && <BonusesGroup />}
          </div>
        ))}

      </div>
      {
        response &&
          <Portal>
              <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackbar}
              >
                  <Alert
                      onClose={handleCloseSnackbar}
                      severity={response?.success ? 'success' : "error"}
                      variant="filled"
                  >
                    {snackbarMessage}
                  </Alert>
              </Snackbar>
          </Portal>
      }
    </>
  )
}

export default GameWidget

{/*todo: need to fix error Snackbar if game already in favourites*/}

