//getProjectSettingsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { RootState } from '../store';


type ProjectSettingsResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type ProjectSettingsError = {
  error: any;
  message: string;
};

interface ProjectSettingsState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: ProjectSettingsState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchProjectSettings
interface ProjectSettingsParams {
  token: string;
  params?: {
    category?: string;
    code?: string;
  };
}

export const fetchProjectSettings = createAsyncThunk<
  ProjectSettingsResponse,
  ProjectSettingsParams,
  { rejectValue: ProjectSettingsError }
>(
  'projectSettings/fetchProjectSettings',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchProjectSettings:', `${config.serverUrl}/setting/get-project-settings`, { token, params });

      const response = await axios.get<ProjectSettingsResponse>(
        `${config.serverUrl}/setting/get-project-settings`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchProjectSettings response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error fetching project settings:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to fetch project settings.',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const projectSettingsSlice = createSlice({
  name: 'projectSettings',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchProjectSettings.fulfilled, (state, action: PayloadAction<ProjectSettingsResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchProjectSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = projectSettingsSlice.actions;

export const projectSettingsReducer = projectSettingsSlice.reducer;



// ---------
// Selectors
// ---------
export const selectProjectSettingsLoading = (state: RootState) => state.projectSettingsReducer.loading;
export const selectProjectSettingsSuccess = (state: RootState) => state.projectSettingsReducer.success;
export const selectProjectSettingsError = (state: RootState) => state.projectSettingsReducer.error;
export const selectProjectSettingsMessages = (state: RootState) => state.projectSettingsReducer.messages;
export const selectProjectSettingsData = (state: RootState) => state.projectSettingsReducer.data;

export const selectProjectSettingsErrorString = (state: RootState): string | null => {
  const error = state.projectSettingsReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectProjectSettingsErrorArray = (state: RootState): string[] | null => {
  const error = state.projectSettingsReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
