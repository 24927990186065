import styles from './Jackpot.module.scss'
import money_2 from 'src/assets/images/money_2.png'
import money_2_2 from 'src/assets/images/money_2_2.png'
import clsx from 'clsx'
import stylesCommon from "../../../common/styles/commonStyles.module.scss"

export const Jackpot = () => {
  return (
    <div className={clsx(styles.component, stylesCommon.group)}>
       Jackpot
      <img src={money_2} alt="money_2" className={styles.money_2}/>
      <img src={money_2_2} alt="money_2_2" className={styles.money_2_2}/>
    </div>
  )
}