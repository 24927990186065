import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { RootState } from '../store';


type UpdatePasswordResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type UpdatePasswordError = {
  error: any;
  message: string;
};

interface UpdatePasswordState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: UpdatePasswordState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchUpdatePassword
interface UpdatePasswordParams {
    token: string;
    params: {
        current_password: string;
        new_password: string;
        confirm_password: string;
    };
}

export const fetchUpdatePassword = createAsyncThunk<
  UpdatePasswordResponse,
  UpdatePasswordParams,
  { rejectValue: UpdatePasswordError }
>(
  'updatePassword/fetchUpdatePassword',
  async ({ token, params }, { rejectWithValue }) => {
    try {
      console.log('Attempting to update password:', `${config.serverUrl}/player/update-password`, { token, params });

      const response = await axios.post(
        `${config.serverUrl}/player/update-password`,
        { token, params },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const data = response.data;
      console.log('fetchUpdatePassword data (after API response):', data);

      if (response.status === 200 && data.success) {
        console.log('Password updated successfully:', data);
        return { success: data.success, data: data.data, messages: data.messages };
      } else {
        console.error('fetchUpdatePassword error:', data.errors || data.message);
        return rejectWithValue({
          error: data.errors || null,
          message: data.message || 'Error updating password.',
        });
      }
    } catch (error: any) {
      console.error('fetchUpdatePassword error catch:', error);

      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          console.error('fetchUpdatePassword - API endpoint not found:', error.response);
          return rejectWithValue({
            error: null,
            message: 'API endpoint not found.',
          });
        } else if (error.response.status === 400) {
          console.error('fetchUpdatePassword - Validation error:', error.response.data.errors);
          return rejectWithValue({
            error: error.response.data.errors,
            message: error.response.data.message || 'Validation error.',
          });
        } else if (error.response.status === 403) {
          // Для ошибки 403 выводим точное сообщение
          console.error('fetchUpdatePassword - Forbidden:', error.response.data.errors);
          return rejectWithValue({
            error: error.response.data.errors || 'Current password is incorrect',
            message: error.response.data.message || 'Access denied.',
          });
        } else {
          console.error('fetchUpdatePassword - Server error:', error.response.data);
          return rejectWithValue({
            error: error.response.data.error || 'Unknown error',
            message: error.response.data.message || 'Server error.',
          });
        }
      } else {
        console.error('fetchUpdatePassword - Network error:', error.message);
        return rejectWithValue({
          error: null,
          message: 'Network error: Unable to connect to the server.',
        });
      }
    }
  }
);



const updatePasswordSlice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpdatePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchUpdatePassword.fulfilled, (state, action: PayloadAction<UpdatePasswordResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchUpdatePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = updatePasswordSlice.actions;

export const updatePasswordReducer = updatePasswordSlice.reducer;



// ---------
// Selectors
// ---------
export const selectUpdatePasswordLoading = (state: RootState) => state.updatePasswordReducer.loading;
export const selectUpdatePasswordSuccess = (state: RootState) => state.updatePasswordReducer.success;
export const selectUpdatePasswordError = (state: RootState) => state.updatePasswordReducer.error;
export const selectUpdatePasswordMessages = (state: RootState) => state.updatePasswordReducer.messages;
export const selectUpdatePasswordData = (state: RootState) => state.updatePasswordReducer.data;

export const selectUpdatePasswordErrorString = (state: RootState): string | null => {
  const error = state.updatePasswordReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectUpdatePasswordErrorArray = (state: RootState): string[] | null => {
  const error = state.updatePasswordReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
