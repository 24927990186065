import React from 'react';

type TextInputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder: string;
  error?: string | string[] | null;
};

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  value = '',
  onChange,
  required = false,
  placeholder,
  error = ''
}) => (
  <div className="form-group mb-3">
    <label htmlFor={id} className="form-label">
      <strong>{placeholder} {required && <span style={{ color: 'red' }}>*</span>}</strong>
    </label>
    <input
      type="text"
      id={id}
      name={name}
      className={`form-control ${error ? 'is-invalid' : ''}`}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={`${placeholder}...`}
      data-error-required={`${placeholder} is required`}
    />
    <div className="invalid-feedback">
      {Array.isArray(error) ? (
        // Если error - это массив строк, выводим каждую строку как элемент списка
        error.map((errMsg, index) => (
          <p key={index}>{errMsg}</p>
        ))
      ) : (
        // Иначе выводим error как строку, если он не null
        error || ''
      )}
    </div>
  </div>
);

export default TextInput;
