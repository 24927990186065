import React, {useState} from 'react'
import {useGetPageListQuery} from '../../../api/api'
import styles from './PageList.module.scss'
import {Pagination} from "../../../common/Pagination"
import {PAGE_PAGINATION_VALUES, SIBLING_COUNT} from "../../../common/constants"
import {Button} from "@mui/material"
import {useNavigate} from "react-router-dom"

export const PageList = () => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(2)

  const navigate = useNavigate()

  const {data, isLoading, error} = useGetPageListQuery({language: 1, page: currentPage, pageSize: itemsPerPage})

  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error loading pages.</p>

  const onReadMore = (slug: string) => {
     navigate(`/page/${slug}`)
  }

  return (
    <div>
      {data && (
        <Pagination
          variant="articles"
          onPageChange={(value) => setCurrentPage(Number(value))}
          totalCount={data.pagination.total_count}
          currentPage={currentPage}
          pageSize={itemsPerPage}
          siblingCount={SIBLING_COUNT}
          selectSettings={{
            value: itemsPerPage.toString(),
            onChangeOption: (value: string) => {
              setItemsPerPage(Number(value))
              setCurrentPage(1)
            },
            arr: PAGE_PAGINATION_VALUES.map((val) => val.toString()),
          }}
        />
      )}
      {data && data.success && data.data.map((page) => (
        <div key={page.id} className={styles.component}>
          <h1>{page.h1}</h1>
          <p><b>meta_title:</b> {page.meta_title}</p>
          <p><b>Description:</b> {page.meta_description}</p>
          <p><b>Body:</b> {page.body.substring(0, 200)}...</p>
          <p><b>Slug:</b> {page.slug}</p>
          <p><b>Created At:</b> {new Date(page.created_at * 1000).toLocaleDateString()}</p>
          <p><b>Updated At:</b> {new Date(page.updated_at * 1000).toLocaleDateString()}</p>
          <p><b>Status:</b> {page.flag_active === 'YES' ? 'Active' : 'Inactive'}</p>
          <Button variant={'contained'} onClick={() => onReadMore(page.slug)}>Read more</Button>
        </div>
      ))}
    </div>
  )
}
