import React from 'react'
import GameWidget from "../elems/GameWidget/GameWidget"
import {ContentBlock} from "../elems/ContentBlock/ContentBlock"
import {Winners} from "../elems/Winners/Winners"
import GameGroups from "../elems/GameGroups/GameGroups"
import {CarouselBlock} from "../elems/CarouselBlock/CarouselBlock"
import {RegistrationBanner} from "../elems/RegistrationBanner/RegistrationBanner"
import {useSelector} from "react-redux"
import {selectIsAuthenticated} from "../../redux/selectors/site/loginSelectors"
import {Jackpot} from "../elems/Jackpot/Jackpot"

export const CommonBlocks = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)

  return (
    <>
      <GameGroups />
      {/*<Providers/>*/}
      <Winners />
      <GameWidget />
      <CarouselBlock />
      {isAuthenticated && <Jackpot /> }
      <ContentBlock blockId={1} />
      {!isAuthenticated && <RegistrationBanner />}
    </>
  )
}