import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Импорт стилей для календаря
import { format } from 'date-fns';

type TextInputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder: string;
  error?: string | string[] | null;
};

const DataInput: React.FC<TextInputProps> = ({
  id,
  name,
  value = '',
  onChange,
  required = false,
  placeholder,
  error = ''
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value ? new Date(value) : null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    console.log(date)
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      onChange({
        target: {
          name: name,
          value: formattedDate, // Преобразование в формат YYYY-MM-DD
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div className="form-group mb-3">
      <label htmlFor={id} className="form-label">
        <strong>{placeholder} {required && <span style={{ color: 'red' }}>*</span>}</strong>
      </label>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        id={id}
        name={name}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        required={required}
        placeholderText={placeholder}
        dateFormat="yyyy-MM-dd"
      />
      <div className="invalid-feedback">
        {Array.isArray(error) ? (
          error.map((errMsg, index) => (
            <p key={index}>{errMsg}</p>
          ))
        ) : (
          error || ''
        )}
      </div>
    </div>
  );
};

export default DataInput;
