import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks"
import { Link, useNavigate } from 'react-router-dom';
import Personal from './Personal/Personal';
import Security from './Security/Security';




const PersonalInformation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState<'Personal' | 'Location' | 'Contacts' | 'Security'>('Personal');


  const renderView = () => {
    switch (currentView) {
      case 'Personal':
        return (
          <Personal />
        );

      case 'Location':
        return (
          <Personal />
        );

      case 'Contacts':
        return (
          <Personal />
        );

      case 'Security':
        return (
          <Security />
        );

      default:
        return null;
    }
  };

  return (
    <div className="mt-2 mb-2">
      <div className="card col-8 offset-2">
        <h1 className="p-2">Profile Information</h1>
        <div className="container mt-5">
          <ul className="nav nav-pills justify-content-between border p-2">
            <li className="nav-item">
              <Link onClick={() => setCurrentView('Personal')} className={currentView === 'Personal' ? "btn btn-success text-white" : "nav-link font-weight-bold"} to="#">Personal</Link>
            </li>
            <li className="nav-item">
              <Link onClick={() => setCurrentView('Location')} className={currentView === 'Location' ? "btn btn-success text-white" : "nav-link font-weight-bold"} to="#">Location</Link>
            </li>
            <li className="nav-item">
              <Link onClick={() => setCurrentView('Contacts')} className={currentView === 'Contacts' ? "btn btn-success text-white" : "nav-link font-weight-bold"} to="#">Contacts</Link>
            </li>
            <li className="nav-item">
              <Link onClick={() => setCurrentView('Security')} className={currentView === 'Security' ? "btn btn-success text-white" : "nav-link font-weight-bold"} to="#">Security</Link>
            </li>
          </ul>
        </div>

        <div className="card-body">
          {renderView()} {/* Вызов функции для рендеринга текущего вида */}
        </div>

      </div>
    </div>
  );
};

export default PersonalInformation;
