import React, {ReactNode} from 'react'
import styles from "./MainLayout.module.scss"
import {ChildrenMenu} from "../ChildrenMenu/ChildrenMenu"
import MyProgressModal from "src/components/elems/MyProgressModal"
import Navigation from "src/components/layouts/Navigation/Navigation"
import Sidebar from "../Sidebar/Sidebar"
import {Container} from '@mui/material'
import Grid from '@mui/material/Grid2'
import Footer from "../Footer/Footer"
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"
import {getNumberFromPx} from "../../../common/helpers"
import {useI18nLanguage} from "../../../common/i18n/useI18nLanguage"

type MainLayoutProps = {
  children: ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({children}) => {
  const {sidebarHeight} = useAppSelector(interfaceSelector)
  const rootStyles = getComputedStyle(document.body)
  const contentGridPadding = getNumberFromPx(rootStyles.getPropertyValue('--content-grid-padding'))

  useI18nLanguage() // connect to i18n

  return (
    <Container className={styles.mainLayout} disableGutters maxWidth={false}>
      <Navigation/>
      <Grid container className={styles.gridContainer} columns={12}>
        <Grid size={2} className={styles.sidebarGrid}>
          <Sidebar/>
          <ChildrenMenu/>
        </Grid>
        <Grid size={10} className={styles.contentGrid} display="flex" flexDirection={'column'}>
          <MyProgressModal/>
          <div style={{
            minHeight: `${sidebarHeight - contentGridPadding}px`,
          }}>{children}</div>
          <Footer/>
        </Grid>
      </Grid>

    </Container>
  )
}

export default MainLayout
