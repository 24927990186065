import React from 'react'
import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {getComparator} from '../utils'
import {Transaction} from "../../../../../api/types"
import stylesCommon from '../TablesCommonStyles.module.scss'
import {SortableFilterableTableCell} from "../SortableFilterableTableCell"
import CopyToClipboard from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {useGetCurrencySelectOptions} from "../useGetCurrencySelectOptions"
import {STATUS_OPTIONS} from "../TablesConstants"
import styles from './DepositWithdrawalTable.module.scss'

const DepositWithdrawalTable: React.FC<SortableTableProps> = ({data, order, orderBy, setOrder, setOrderBy, filtersState}) => {
  const {filters, setFilters} = filtersState

  const {currencySelectOptions} = useGetCurrencySelectOptions()

  const handleRequestSort = (property: keyof Transaction) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedData = [...data].sort(getComparator<Transaction,  keyof Transaction>(order, orderBy))

  return (
    <TableContainer>
      <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" className={styles.table}>
        <TableHead>
          <TableRow>
            <SortableFilterableTableCell
              label="UID"
              orderBy="uid"
              currentOrderBy={orderBy}
              order={order}
              filterValue={filters.uidFilter}
              onSort={() => handleRequestSort('uid')}
              onFilterChange={(e) => setFilters({...filters, uidFilter: e.target.value})}
              placeholder="Filter by UID"
            />
            <SortableFilterableTableCell
              label="Date"
              orderBy="date"
              currentOrderBy={orderBy}
              order={order}
              filterValue={filters.dateFilter}
              onSort={() => handleRequestSort('date')}
              onFilterChange={(e) => setFilters({...filters, dateFilter: e.target.value})}
              placeholder="Filter by Date"
            />
            <SortableFilterableTableCell
              label="Amount"
              orderBy="amount"
              currentOrderBy={orderBy}
              order={order}
              filterValue={filters.amountFilter}
              onSort={() => handleRequestSort('amount')}
              onFilterChange={(e) => setFilters({...filters, amountFilter: e.target.value})}
              placeholder="Filter by Amount"
            />
            <SortableFilterableTableCell
              label="Currency"
              orderBy="currency_title"
              currentOrderBy={orderBy}
              order={order}
              filterValue={filters.currencyFilter}
              onSort={() => handleRequestSort('currency_code')}
              onFilterChange={(e) => setFilters({...filters, currencyFilter: e.target.value})}
              placeholder="Filter by Currency"
              selectOptions={currencySelectOptions}
            />
            <SortableFilterableTableCell
              label="Status"
              orderBy="status"
              currentOrderBy={orderBy}
              order={order}
              filterValue={filters.statusFilter}
              onSort={() => handleRequestSort('status')}
              onFilterChange={(e) => setFilters({...filters, statusFilter: e.target.value})}
              placeholder="Filter by Status"
              selectOptions={STATUS_OPTIONS}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => (
            <TableRow hover key={row.id}>
              <TableCell>
                <Box className={stylesCommon.uid}>
                  {row.uid}
                  <CopyToClipboard text={row.uid}>
                    <IconButton size="small">
                      <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                  </CopyToClipboard>
                </Box>
              </TableCell>
              <TableCell>{new Date(row.date * 1000).toLocaleDateString()}</TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.currency_code}</TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type SortableTableProps = {
  data: Transaction[]
  order: 'asc' | 'desc'
  orderBy: keyof Transaction
  setOrder: (value: 'asc' | 'desc') => void
  setOrderBy: (value: keyof Transaction) => void
  filtersState: {
    filters: {
      uidFilter: string
      dateFilter: string
      amountFilter: string
      currencyFilter: string
      statusFilter: string
    }
    setFilters: (filters: FiltersState) => void
  }
}

type FiltersState = {
  uidFilter: string
  dateFilter: string
  amountFilter: string
  currencyFilter: string
  statusFilter: string
}

export default DepositWithdrawalTable
