import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../hooks"
import { Link, useNavigate } from 'react-router-dom';
import Alert from '../../../layouts/ui/alert/Alert';
import { fetchGetPersonalInfo } from 'src/redux/actions/player/getPersonalInfoActions';
import { fetchUpdatePersonalInfo } from 'src/redux/actions/player/updatePersonalInfoActions';
import {
    selectUpdatePersonalInfo,
    selectUpdatePersonalInfoMessages,
    selectUpdatePersonalInfoLoading,
    selectUpdatePersonalInfoError,
    selectUpdatePersonalInfoErrorMessage,
    selectUpdatePersonalInfoErrorArray,
    selectUpdatePersonalInfoSuccess
} from 'src/redux/selectors/player/updatePersonalInfoSelectors';

import {
    selectGetPersonalInfo,
    selectGetPersonalInfoMessages,
    selectGetPersonalInfoLoading,
    selectGetPersonalInfoError
} from 'src/redux/selectors/player/getPersonalInfoSelectors';


import { selectIsAuthenticated, selectToken } from '../../../../redux/selectors/site/loginSelectors';

interface PersonalInfo {
    code: string;
    value: any;
    approve: string;
    name: string;
    id: string;
    flag_editable: string;
}

interface PersonalInfoState {
    [key: string]: any; // Ключи — строки, значения могут быть разными
}


const Personal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    const getPersonalInfo: PersonalInfo[] = useSelector(selectGetPersonalInfo);
    const getPersonalInfoMessages = useSelector(selectGetPersonalInfoMessages);
    const getPersonalInfoLoading = useSelector(selectGetPersonalInfoLoading);
    const getPersonalInfoError = useSelector(selectGetPersonalInfoError);

    const updatePersonalInfo = useSelector(selectUpdatePersonalInfo);
    const updatePersonalInfoMessages = useSelector(selectUpdatePersonalInfoMessages);
    const updatePersonalInfoLoading = useSelector(selectUpdatePersonalInfoLoading);
    const updatePersonalInfoError = useSelector(selectUpdatePersonalInfoError);
    const updatePersonalInfoErrorMessage = useSelector(selectUpdatePersonalInfoErrorMessage);
    const updatePersonalInfoErrorArray = useSelector(selectUpdatePersonalInfoErrorArray);
    const updatePersonalInfoSuccess = useSelector(selectUpdatePersonalInfoSuccess);

    const [formState, setFormState] = useState<PersonalInfoState>({});
    const [initialState, setInitialState] = useState<PersonalInfoState>({}); // Начальные значения из API
    const [isEditing, setIsEditing] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false); // Флаг успешного обновления
    const [showErrors, setShowErrors] = useState(false); //это чтобы алерт с ошибками

    useEffect(() => {
        if (isAuthenticated && token) {
            dispatch(fetchGetPersonalInfo(token));
        }
    }, [dispatch, isAuthenticated, token]);

    useEffect(() => {
        console.log('getPersonalInfo', getPersonalInfo);
    }, [getPersonalInfo]);

    useEffect(() => {
        const initialFormValues: Record<string, any> = {};
        if (getPersonalInfoError || getPersonalInfo === null) {
            return;
        }
        getPersonalInfo.forEach((item: PersonalInfo) => {
            initialFormValues[item.code] = item.value;
        });
        setFormState(initialFormValues);
        setInitialState(initialFormValues);
    }, [getPersonalInfo]);

    useEffect(() => {
        if (updatePersonalInfoError) {
            setUpdateSuccess(false);
            setShowErrors(true); // раз ошибки есть то разблокируем показ окна алерта
        } else if (updatePersonalInfoSuccess) {
            setUpdateSuccess(true);
            setShowErrors(false);
        }
    }, [updatePersonalInfoError, updatePersonalInfoSuccess]);

    
    useEffect(() => {

        setUpdateSuccess(false);
        setShowErrors(false);

    }, []);

    const handleInputChange = (code: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let value = e.target.value;
        console.log(code, value);
        const updatedFormState = {
            ...formState,
            [code]: value,  // Используем `code` как ключ, чтобы обновить соответствующее поле
        };

        setFormState(updatedFormState);

        // Проверяем, изменились ли данные по сравнению с начальным состоянием
        setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState));
    };

    const handleErrorClose = () => {
        setShowErrors(false); // Скрываем ошибки при нажатии на крестик
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('Submitted data:', formState);

        setUpdateSuccess(false);
        setShowErrors(false);

        if (!isEditing) {
            return;
        }

        if (isAuthenticated && token) {
            dispatch(fetchUpdatePersonalInfo(token, formState));
        }

    };

    const renderInputField = (item: PersonalInfo) => {
        const labelText = item.approve === 'request' ? `${item.name} (update after approve admin)` : item.name;

        if (item.code === 'gender') {
            return (
                <div className="mb-3" key={item.id}>
                    <label htmlFor={item.code} className="form-label"><strong>{labelText}</strong></label>
                    <select
                        id={item.code}
                        className="form-control"
                        name={item.code}
                        value={formState[item.code] || ''}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(item.code, e)}
                        disabled={item.flag_editable === 'NO'}
                    >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                </div>
            );
        }

        // Для всех остальных полей рендерим текстовое поле
        return (
            <div className="mb-3" key={item.id}>
                <label htmlFor={item.code} className="form-label"><strong>{labelText}</strong></label>
                <input
                    type="text"
                    id={item.code}
                    className="form-control"
                    name={item.code}
                    value={formState[item.code] || ''}
                    onChange={(e) => handleInputChange(item.code, e)}
                    disabled={item.flag_editable === 'NO'}
                />
            </div>
        );
    };



    return (

        <>
            {getPersonalInfoLoading ? (
                <p>Loading...</p>
            ) : getPersonalInfoError ? (
                <p>Error: {getPersonalInfoError}</p>
            ) : getPersonalInfo ? (
                <div className="row">
                    <div className="col-lg-12">
                        <form onSubmit={handleSubmit}>
                            {/* Рендерим поля динамически */}
                            {getPersonalInfo.map(renderInputField)}

                            {/* Кнопка будет disabled, если данные не изменились */}
                            <button type="submit" className="btn btn-primary" disabled={!isEditing}>
                                {updatePersonalInfoLoading ? 'Loading...' : 'UPDATE DATA'}
                            </button>
                        </form>

                        {/* Сообщение об успешном обновлении */}
                        {updateSuccess && (
                            <Alert><strong>Success!</strong> Password updated successfuly!</Alert>
                        )}

                        {showErrors && updatePersonalInfoErrorArray && updatePersonalInfoErrorArray.length > 0 && (

                            <Alert onClose={handleErrorClose} type="error">
                                {updatePersonalInfoErrorArray.map((error: string, index: number) => {
                                    return (<p key={index}>{error}</p>);
                                })}
                            </Alert>
                        )}

                    </div>
                </div>
            ) : (<p>No data...</p>)}
        </>
    )
};

export default Personal;
