import currency_btc from "src/assets/images/icons/headerCurrencies/currency_bitcoin.svg"
import currency_euro from "src/assets/images/icons/headerCurrencies/currency_euro.svg"
import currency_dollar from "src/assets/images/icons/headerCurrencies/currency_dollar.svg"
import styles from "./CurrencySelectors.module.scss"
import React from "react"

export const currencySelectors = (currency_code: string) => {
  switch (currency_code) {
    case 'BTC':
      return <img src={currency_btc} alt={currency_code}/>
    case 'EUR':
      return <img src={currency_euro} alt={currency_code}/>
    case 'USD':
      return <img src={currency_dollar} alt={currency_code}/>
    default:
      return <div>{currency_code}</div>
  }
}

export const currencySelector = (currency_code: string) => (
  <img
    src={`/images/icons/currencies/currency_${currency_code}.svg`}
    className={styles.currencySymbol}
    alt={`${currency_code} currency`}
  />
)