//BonusHistory.js
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const BonusHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Пример. Потом ошибки и загрузку выводим из хранилища.
  // const loading = useSelector(selectLoading);
  // const error = useSelector(selectError);

  //Пример. Локальное состояние. loading и error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 200);
    
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : <div>
        <h1>BonusHistory</h1>
        {/* <GameGroups />
        <Providers /> */}
      </div>
      }
    </>
  );
}

// export default withAuth(BonusHistory);
export default BonusHistory;