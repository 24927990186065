import styles from "./ListBalancesDropdown.module.scss"
import {useSelector} from "react-redux"
import {selectToken} from "../../../redux/selectors/site/loginSelectors"
import {useGetListBalancesQuery} from "../../../api/api"
import {MoneyBlock} from "../../../common/MoneyBlock/MoneyBlock"
import React, {useEffect, useMemo, useState} from "react"
import clsx from "clsx"
import Box from "@mui/material/Box"
import {CurrencyData} from "../../../api/types"
import Tippy_up_border from "src/assets/images/icons/Tippy_up_border.svg"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {switchAccount} from "../../../redux/slices/switchAccountSlice"
import check_account_dropdown from "src/assets/images/icons/check_account_dropdown.svg"
import {
  interfaceSelector,
  setBalanceLoadingAfterSwitch,
  setListBalancesDropdownShown
} from "../../../redux/slices/interfaceSlice"
import {BrightTabs} from "../../../common/BrightTabs/BrightTabs"
import {currencySelector} from "../../../common/CurrencySelectors/CurrencySelectors"

type Props = {
  balance: {
    currency: string
    amount: string
    currency_symbol: string
  }
}

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`listbalancesdropdown-tabpanel-${index}`}
      aria-labelledby={`listbalancesdropdown-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

export const ListBalancesDropdown = ({
                                       balance,
                                     }: Props) => {
  const token = useAppSelector(selectToken)
  const {isListBalancesDropdownShown} = useAppSelector(interfaceSelector)

  const {data: listBalancesData, refetch} = useGetListBalancesQuery({token: token ? token : ''})

  const [activeTab, setActiveTab] = useState(0)

  // Search flag_main in the listBalancesData object, to set active tab
  const flagMainResult = useMemo(() => {
    if (!listBalancesData || !listBalancesData.data) {
      return null // return null if no data is available
    }
    if (listBalancesData.data.fiat.some((item) => item.flag_main === "YES")) {
      return "fiat"
    }
    if (listBalancesData.data.crypto.some((item) => item.flag_main === "YES")) {
      return "crypto"
    }
    return null // return null if flag_main is not found
  }, [listBalancesData])

  useEffect(() => {
    if (!listBalancesData || !listBalancesData.data) {
      setActiveTab(0) // if no data, first tab
      return
    }
    if (flagMainResult === "crypto") {
      setActiveTab(0)
    } else if (flagMainResult === "fiat") {
      setActiveTab(1)
    } else {
      setActiveTab(0) // if no flag found, first tab
    }
  }, [flagMainResult])

  const CurrencyContent = ({items}: { items: CurrencyData[] }) => {
    const dispatch = useAppDispatch()
    const token = useSelector(selectToken)

    const onSwitchAccountClick = async (currencyId: number) => {
      if (token) {
        dispatch(setBalanceLoadingAfterSwitch(true))
        dispatch(setListBalancesDropdownShown(false))
        await dispatch(switchAccount({token: token, currencyId: currencyId}))
        refetch()
      }
    }

    return (
      <div className={styles.currencyContent}>
        {items.map((item: CurrencyData) => (
          <div key={item.currency_code} className={styles.currencyElement}
               onClick={() => onSwitchAccountClick(item.currency_id)}>
            <div className={styles.currencyCode}>
              {currencySelector(item.currency_code)}
              {item.currency_code}
            </div>
            <div className={styles.amount}>
              {item.flag_main === 'YES' &&
                  <img src={check_account_dropdown} alt="check_account_dropdown" className={styles.checkedAccountImg}/>}
              {Number(item.amount)}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const tabs = [
    {label: "Crypto"},
    {label: "Fiat"},
  ]

  return (
    <>
      <MoneyBlock
        type={"Real"}
        currency={balance.currency}
        amount={balance.amount}
      >
        <div className={styles.listBalancesDropdown}>
          <div
            className={clsx(
              "dropdown-menu",
              isListBalancesDropdownShown && "show",
              styles.menu
            )}
          >
            {isListBalancesDropdownShown && <img src={Tippy_up_border} alt="Tippy_up" className={styles.tippy}/>}

            <BrightTabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={handleChange}
              className={styles.tabs}
            />

            <Box>
              <CustomTabPanel value={activeTab} index={0}>
                {listBalancesData?.data && <CurrencyContent items={listBalancesData?.data?.crypto}/>}
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={1}>
                {listBalancesData?.data && <CurrencyContent items={listBalancesData?.data?.fiat}/>}
              </CustomTabPanel>
            </Box>
          </div>
        </div>
      </MoneyBlock>
    </>
  )
}