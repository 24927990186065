import { AppBar } from "@mui/material";
import { styled } from "@mui/system";

export const AppBarStyled = styled(AppBar)(() => ({
  backgroundColor: "var(--background-surface-elevation-1)", 
  height: "var(--navbar-height)",
  padding: "0 40px",
  color: "var(--text-base-secondary)",
  justifyContent: "center",
}));


