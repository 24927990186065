// registerValidationFirstScreenReducer
import {
  ACTION_REGISTER_VALIDATE_FIRST_SCREEN_REQUEST,
  ACTION_REGISTER_VALIDATE_FIRST_SCREEN_SUCCESS,
  ACTION_REGISTER_VALIDATE_FIRST_SCREEN_FAILURE,
  ACTION_REGISTER_VALIDATE_FIRST_SCREEN_CLEAR_ERROR
} from '../../actions/site/registerValidationFirstScreenAction';

const initialState = {
  success: false,
  loading: false,
  errors: null,
  data: [],
  messages: null,
};

const registerValidationFirstScreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_REGISTER_VALIDATE_FIRST_SCREEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false
      };

    case ACTION_REGISTER_VALIDATE_FIRST_SCREEN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.messages,
        success: true
      };

    case ACTION_REGISTER_VALIDATE_FIRST_SCREEN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        success: false
      };

    case ACTION_REGISTER_VALIDATE_FIRST_SCREEN_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
        success: false
      };

    default:
      return state;
  }
};

export default registerValidationFirstScreenReducer;
