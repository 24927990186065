// registerValidationFirstScreen
import axios from 'axios';
import config from '../../../config';
import { Action, Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

// Константы для типов действий
export const ACTION_REGISTER_VALIDATE_FIRST_SCREEN_REQUEST      = 'ACTION_REGISTER_VALIDATE_FIRST_SCREEN_REQUEST';
export const ACTION_REGISTER_VALIDATE_FIRST_SCREEN_SUCCESS      = 'ACTION_REGISTER_VALIDATE_FIRST_SCREEN_SUCCESS';
export const ACTION_REGISTER_VALIDATE_FIRST_SCREEN_FAILURE      = 'ACTION_REGISTER_VALIDATE_FIRST_SCREEN_FAILURE';
export const ACTION_REGISTER_VALIDATE_FIRST_SCREEN_CLEAR_ERROR  = 'ACTION_REGISTER_VALIDATE_FIRST_SCREEN_CLEAR_ERROR';

interface SuccessResponse {
    success: true;
    data: null;
    messages: string[];
}

interface ErrorResponse {
    success: false;
    errors: string[];
    message?: string;
}

// Объединённый тип для успешного или неуспешного ответа
type ApiResponse = SuccessResponse | ErrorResponse;

// Функции-предикаты для проверки типа ответа
const isSuccessResponse = (response: ApiResponse): response is SuccessResponse => response.success === true;
const isErrorResponse = (response: ApiResponse): response is ErrorResponse => response.success === false;

// Интерфейсы для экшенов
interface RequestAction {
    type: typeof ACTION_REGISTER_VALIDATE_FIRST_SCREEN_REQUEST;
}

interface SuccessAction {
    type: typeof ACTION_REGISTER_VALIDATE_FIRST_SCREEN_SUCCESS;
    payload: { data: null; messages: string[] };
}

interface FailureAction {
    type: typeof ACTION_REGISTER_VALIDATE_FIRST_SCREEN_FAILURE;
    payload: { errors: string[] | null; message: string };
}

interface ClearAction {
    type: typeof ACTION_REGISTER_VALIDATE_FIRST_SCREEN_CLEAR_ERROR;
}

export type RegisterValidateFirstScreenActions = RequestAction | SuccessAction | FailureAction | ClearAction;

// Экшены для получения данных
export const actionRegisterValidateFirstScreenRequest = (): RequestAction => ({
    type: ACTION_REGISTER_VALIDATE_FIRST_SCREEN_REQUEST,
});

export const actionRegisterValidateFirstScreenSuccess = (data: null, messages: string[]): SuccessAction => ({
    type: ACTION_REGISTER_VALIDATE_FIRST_SCREEN_SUCCESS,
    payload: { data, messages },
});

export const actionRegisterValidateFirstScreenFailure = (errors: string[] | null, message: string): FailureAction => ({
    type: ACTION_REGISTER_VALIDATE_FIRST_SCREEN_FAILURE,
    payload: { errors, message },
});

export const actionRegisterValidateFirstScreenClearError = (): ThunkAction<void, RootState, unknown, ClearAction> => 
    (dispatch, getState) => {
        const state: RootState = getState(); // Получаем состояние
        console.log('Current state:', state); // Можем использовать его для отладки, если нужно

        dispatch({
            type: ACTION_REGISTER_VALIDATE_FIRST_SCREEN_CLEAR_ERROR,
        });
    };

// Экшен
export const fetchRegisterValidateFirstScreen = (
    userData: any
): ThunkAction<Promise<void>, RootState, unknown, Action<any>> => async (dispatch: Dispatch<RegisterValidateFirstScreenActions>) => {
    dispatch(actionRegisterValidateFirstScreenRequest());

    try {
        console.log('Attempting to fetchRegisterValidateFirstScreen:', `${config.serverUrl}/site/register-validation-first-screen`,  userData );

        const response = await axios.post<ApiResponse>(`${config.serverUrl}/site/register-validation-first-screen`,
            userData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

        const data = response.data;
        console.log('fetchRegisterValidateFirstScreen data (after ajax):', response);

        if (isSuccessResponse(data)) {
            console.log('actionRegisterValidateFirstScreenSuccess data.data:', data.data);
            dispatch(actionRegisterValidateFirstScreenSuccess(data.data, data.messages));
        } else if (isErrorResponse(data)) {
            console.error('fetchRegisterValidateFirstScreen error:', data.errors);
            dispatch(actionRegisterValidateFirstScreenFailure(data.errors, data.message || 'Error fetching.'));
        }
    } catch (error: any) {
        console.error('fetchRegisterValidateFirstScreen error catch:', error.response);
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) {
                dispatch(actionRegisterValidateFirstScreenFailure(null, 'API endpoint not found.'));
            } else if (error.response.status === 400) {
                console.error(`fetchRegisterValidateFirstScreen validation error catch:`, error.response.data.errors);
                dispatch(actionRegisterValidateFirstScreenFailure(error.response.data.errors, error.response.data.statusText || 'Validation error'));
            } else {
                dispatch(actionRegisterValidateFirstScreenFailure(error.response.data.error || 'Unknown error', error.response.data.message || 'Server error.'));
            }
        } else {
            console.error('fetchRegisterValidateFirstScreen network error:', error.message);
            dispatch(actionRegisterValidateFirstScreenFailure(null, 'Network error: Unable to connect to the server.'));
        }
    }
};

