import { useLocation, useParams } from 'react-router-dom';
import MainLayout from '../MainLayout/MainLayout';
import AuthLayout from '../AuthLayout/AuthLayout';
import React, { ReactNode } from 'react';

type LayoutWrapperProps = {
    children: ReactNode;
};

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
    const location = useLocation();
    const { param } = useParams();  // Параметр маршрута

    // console.log('Current path:', location.pathname);  // Логируем текущий путь
    // console.log('Param:', param);

    // Проверка на /auth без параметров
    // if (location.pathname === '/auth') {
    //     console.log('LayoutWrapper', 'AuthLayout /auth');
    //     return <AuthLayout>{children}</AuthLayout>;
    // }

    // // Проверка для путей с параметром, например /auth/login или /auth/signup
    // if (param === 'login' || param === 'signup') {
    //     console.log('LayoutWrapper', `AuthLayout /auth/${param}`);
    //     return <AuthLayout>{children}</AuthLayout>;
    // }

    if (location.pathname.startsWith('/auth')) {
        return <AuthLayout>{children}</AuthLayout>;
    }
    if (location.pathname.startsWith('/robots.txt')) {
        return <>{children}</>;
    }

    // Если ни одно из условий не подошло, используем MainLayout
    return <MainLayout>{children}</MainLayout>;
};

export default LayoutWrapper;
